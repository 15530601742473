import { Buffer } from "buffer"
import { HaxeEnum } from "haxeformat"

type Nullable<T> = T | undefined | null

export class _Answer extends HaxeEnum {
    static readonly enum = "_Answer"

    static getEnumConstructs() {
        return [
            ABlocks,
            ASet,
            APosSaved,
            AMap,
            AGenerate,
            ARedirect,
            AMessage,
            ANothing,
            AValue,
            AShowError,
            APong,
            ASetMany,
        ]
    }
}

export class ABlocks extends _Answer {
    static readonly tag = "ABlocks"

    a: Nullable<number>[]

    constructor(a: number[]) {
        super()
        this.a = a
    }

    getParams(): any[] {
        return [this.a]
    }
}

export class ASet extends _Answer {
    static readonly tag = "ASet"

    x: number
    y: number
    z: number
    k: number

    constructor(x: number, y: number, z: number, k: number) {
        super()
        this.x = x
        this.y = y
        this.z = z
        this.k = k
    }

    getParams(): any[] {
        return [this.x, this.y, this.z, this.k]
    }
}

export class APosSaved extends _Answer {
    static readonly tag = "APosSaved"

    getParams(): any[] {
        return []
    }
}

export interface ZoneStruct {
    _d: Nullable<Date>
    _n: Nullable<string>
    _u: Nullable<string>
    _g: boolean
    _p: boolean
}

export class AMap extends _Answer {
    static readonly tag = "AMap"

    mx: number
    my: number
    data: Buffer
    patches: Nullable<Buffer>
    zones: Nullable<ZoneStruct>[]

    constructor(mx: number, my: number, data: Buffer, patches: Nullable<Buffer>, zones: Nullable<ZoneStruct>[]) {
        super()
        this.mx = mx
        this.my = my
        this.data = data
        this.patches = patches
        this.zones = zones
    }

    getParams(): any[] {
        return [this.mx, this.my, this.data, this.patches, this.zones]
    }
}

export class AGenerate extends _Answer {
    static readonly tag = "AGenerate"

    mx: number
    my: number

    constructor(mx: number, my: number) {
        super()
        this.mx = mx
        this.my = my
    }

    getParams() {
        return [this.mx, this.my]
    }
}

export class ARedirect extends _Answer {
    static readonly tag = "ARedirect"

    url: string

    constructor(url: string) {
        super()
        this.url = url
    }

    getParams() {
        return [this.url]
    }
}

export class AMessage extends _Answer {
    static readonly tag = "AMessage"

    text: string
    error: boolean

    constructor(text: string, error: boolean) {
        super()
        this.text = text
        this.error = error
    }

    getParams() {
        return [this.text, this.error]
    }
}

export class ANothing extends _Answer {
    static readonly tag = "ANoting"

    getParams(): any[] {
        return []
    }
}

export type AValueData = {
    // CFix
    water?: number
    invalid?: number
    // CCountBlocks
    count?: number
    surf?: number
}

export class AValue extends _Answer {
    static readonly tag = "AValue"

    v: AValueData

    constructor(v: AValueData) {
        super()
        this.v = v
    }

    getParams() {
        return [this.v]
    }
}

export class AShowError extends _Answer {
    static readonly tag = "AShowError"

    text: string

    constructor(text: string) {
        super()
        this.text = text
    }

    getParams() {
        return [this.text]
    }
}

export class APong extends _Answer {
    static readonly tag = "APong"

    i: number

    constructor(i: number) {
        super()
        this.i = i
    }

    getParams() {
        return [this.i]
    }
}

export class ASetMany extends _Answer {
    static readonly tag = "ASetMany"

    a: number[]

    constructor(a: number[]) {
        super()
        this.a = a
    }

    getParams() {
        return [this.a]
    }
}
