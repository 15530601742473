import kubeRight from "../img/game/kube_right.jpg"
import { Minimap } from "./Minimap"
import { PowerBar } from "./PowerBar"
import { ZoneInfoBox } from "./ZoneInfoBox"
import { css } from "@emotion/react"
import { Game } from "kube-engine/src/game"

const classes = {
    right: css`
        background: url(${kubeRight});
        width: 196px;
        height: 320px;
    `,
}

export const RightContainer = ({ game }: { game: Game }) => {
    return (
        <div css={classes.right}>
            <PowerBar game={game} />
            <Minimap game={game} />
            <ZoneInfoBox game={game} />
        </div>
    )
}
