export const HelpPage = () => {
    return (
        <div>
            <h1>
                <img src="/img/loc/fr/h1_help.gif" alt="Aide" />
            </h1>

            <p>
                <img src="/img/worldkube_big.jpg" className="helpimg" /> Bienvenue sur <b>Kube</b> !
            </p>

            <p>Dans ce jeu en 3D, vous incarnez un colon qui découvre un nouveau monde, sauvage et étrange.</p>

            <p>
                Le monde de Kube est en effet composé de milliards de petits <b>kubes</b> que vous pouvez déplacer à
                votre guise, de façon à creuser des tunnels, construire des ponts ou des forteresses, ou encore créer
                des oeuvres originales.
            </p>

            <h2>1. Contrôles</h2>

            <p>
                Il existe deux façons de vous déplacer dans le monde de Kube : le clavier seul ou le clavier plus la
                souris.
            </p>

            <h3>Clavier Seul</h3>

            <ul>
                <li>
                    <img src="/img/k_arrows.gif" /> <em>Flèches directionnelles</em> ou le pavé numérique pour vous
                    déplacer.
                </li>
                <li>
                    <img src="/img/k_space.gif" /> <em>Espace</em> pour sauter.
                </li>
            </ul>

            <h3>Clavier et Souris</h3>

            <ul>
                <li>
                    <img src="/img/k_press.gif" /> <em>Bouton de la souris appuyé</em> puis déplacez la souris pour
                    regarder autour de vous. Cela activera le mode souris.
                </li>

                <li>
                    <img src="/img/k_press.gif" /> <span className="more">+</span> <img src="/img/k_arrows.gif" />{" "}
                    <em>Bouton de la souris appuyé</em> plus <em>haut</em> ou <em>bas</em> pour avancer/reculer. Les
                    flèches gauche et droite vous permettent de vous déplacer latéralement, et la souris de tourner.
                </li>
            </ul>

            <h3>Touches avancées</h3>

            <p>Il existe aussi des touches supplémentaires dont voici les détails :</p>

            <ul>
                <li>
                    <img src="/img/k_ctrl.gif" /> <em>Ctrl</em> : Vous permet aussi de sauter. Conseillé pour le
                    contrôle avec le clavier et la souris en même temps.
                </li>
                <li>
                    <img src="/img/k_esc.gif" /> <em>Échap</em> : annule la sélection du kube actuel (mode main).
                </li>
                <li>
                    <img src="/img/k_shift.gif" /> <em>Shift</em> : si maintenu appuyé, vous permet de marcher et vous
                    empêche de tomber.
                </li>
                <li>
                    <img src="/img/k_u.gif" /> <em>U</em> : permet d'annuler le dernier bloc pris/posé (impossible après
                    s'être déplacé).
                </li>
                <li>
                    <img src="/img/k_p.gif" /> <em>P</em> : modifie le mode "privé" de la prise de photo (Kubix 3000
                    seulement).
                </li>
            </ul>

            <h2>2. Les Zones</h2>

            <p>
                <img src="/img/zones.gif" className="helpimg" />
            </p>

            <p>
                Le monde de Kube est divisé en <strong>Zones</strong> de taille 32x32 kubes. Vous pouvez prendre et
                poser des kubes dans les zones publiques ou non habitées <img src="/img/icons/unlocked.gif" />, mais
                vous ne pouvez pas modifier une zone louée <img src="/img/icons/locked.gif" /> par un joueur si ce
                dernier ne vous en a pas donné le droit.
            </p>

            <p>
                Vous pouvez louer votre <strong>propre Zone</strong> si celle-ci est libre en échange de
                <span className="token">
                    jetons Muxxu <img src="http://muxxu.com/img/token.png" alt="" className="token" />
                </span>
                . Il vous faudra au préalable vous rendre à l'emplacement de cette zone pour pouvoir la louer.
            </p>

            <p>
                Les droits de modification d'une Zone peuvent être délégués à d'autres joueurs par le locataire de la
                zone. Cela permet de partager une même Zone et donc d'y construire des projets collectifs.
            </p>

            <h2>3. Les Dolmens</h2>

            <p>
                <img src="/img/dolmen.gif" className="helpimg" />
            </p>

            <p>
                Parcourez le monde à la recherche des kubes noirs. Une fois ceux-ci assemblés dans un mégakube de
                3x3x3=27 kubes noirs, ils se transmuteront en un <b>Dolmen</b>.
            </p>

            <p>
                Touchez ce Dolmen pour compléter votre <a href="/map">Carte</a> et ainsi pouvoir petit à petit
                cartographier le monde de Kube.
            </p>

            <p>
                Vous pouvez aussi trouver les <b>Dolmens</b> que les autres joueurs ont construits, car une fois créés
                ces derniers sont indestructibles.
            </p>

            <div className="clear"></div>

            <h2>4. Buts du Jeu</h2>

            <p>
                Il n'y a pas un seul unique but du jeu dans Kube mais plusieurs. À vous de savoir ce que vous voulez
                réaliser :
            </p>

            <ul>
                <li>
                    <img src="/img/icons/play.gif" /> <em>Construction</em> : Assemblez seul ou a plusieurs des{" "}
                    <b>kubes</b> de façon à construire une maison, un château, une tour, un labyrinthe, etc... N'hésitez
                    pas ensuite à venir présenter vos projets sur le <a href="http://muxxu.com/go/forum">Forum</a> de
                    façon à ce qu'ils puissent être visités par d'autres joueurs. Vous pouvez aussi immortaliser vos
                    oeuvres en prenant des Photos grâce aux appareils de la <a href="/shop">Boutique</a>.
                </li>

                <li>
                    <img src="/img/icons/home.gif" /> <em>Collection</em> : Il existe un grand nombre de kubes dont
                    certains très rares. Parcourez le monde ou faites des échanges entre joueurs pour obtenir la
                    collection complète de tous les <b>kubes</b> existants. Certaines combinaisons pourront d'ailleurs
                    créer de nouveaux kubes !
                </li>

                <li>
                    <img src="/img/icons/photo.gif" /> <em>Exploration</em> : Parcourez le monde sauvage de Kube, loin
                    des centre-ville hyperurbanisés ! Collectez les kubes noirs et touchez les Dolmens de façon à
                    compléter votre <a href="/map">Carte</a>, et vous acquérez peut-être un jour le titre de{" "}
                    <b>cartographe</b> !
                </li>
            </ul>

            <p>
                Et maintenant ? <a href="/">A vous de jouer !</a>
            </p>

            <h2>A propos</h2>

            <p>
                Kube est réalisé en Flash et n'utilise pas la carte 3D de votre ordinateur, il est donc limité
                techniquement par rapport à d'autres jeux 3D similaires.
            </p>

            <p>
                Kube a été créé en Juin 2009, à l'époque où Minecraft n'était encore qu'un simple éditeur en 3D sans
                aucune règle du jeu. Kube offre un univers unique persistant pour l'ensemble des joueurs, ainsi qu'un
                équilibre différent entre l'exploration et la construction. Nous espérons que vous prendrez plaisir à
                jouer à Kube tel que nous l'avons créé.
            </p>
        </div>
    )
}
