import kubeMainBottom from "../img/mainbottom.gif"
import kubeMainContent from "../img/maincontent.gif"
import kubeMainTop from "../img/maintop.gif"
import { EmotionProps } from "../types"
import { GenericBox } from "./GenericBox"
import { FC, PropsWithChildren } from "react"

export const KubeMainBox: FC<PropsWithChildren & EmotionProps> = ({ children, className }) => {
    return (
        <GenericBox
            className={className}
            top={kubeMainTop}
            topHeight={11}
            bottom={kubeMainBottom}
            bottomHeight={16}
            bg={kubeMainContent}
            maxWidth={900}>
            {children}
        </GenericBox>
    )
}
