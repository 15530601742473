import { CHUNK_PER_KUBE_CHUNK, CHUNK_SIZE } from "../defaultConfig"
import { Block, BlocksColors } from "../game/Block"
import { ZoneStruct } from "../network/enums/_Answer"
import { CActiveKube } from "../network/enums/_Cmd"
import { NdArray } from "ndarray"
import { Engine } from "noa-engine"
import pako from "pako"

// A chunk request from noa
export interface ChunkRequest {
    id: string
    data: NdArray
    kX: number
    kY: number
    diffX: number
    diffY: number
}

// A Kube chunk
export interface KChunkData {
    kX: number
    kY: number
    loadedChunks: Set<string>
    requested: ChunkRequest[]

    zones?: (ZoneStruct | null)[]
    data?: Uint8Array
    requestSize?: number
}

export interface ChunkUserData {
    zone: ZoneStruct | null
    minimap?: ImageData
    requestSize?: number
}

export function mod(value: number, n: number) {
    return ((value % n) + n) % n
}

export function uncompress(buffer: Uint8Array): Uint8Array {
    // Assume zlib for now.
    let uncompressed = pako.inflate(buffer)
    return uncompressed
}

export function compress(buffer: Uint8Array): Uint8Array {
    // Assume zlib for now.
    let compressed = pako.deflate(buffer)
    return compressed
}

export function applyPatches(buffer: Uint8Array, patches: Uint8Array | null) {
    if (!patches) return
    for (let i = 0; i < patches.length; i += 4) {
        setBlockInKChunk(buffer, patches[i], patches[i + 2], patches[i + 1], patches[i + 3])
    }
}

export function getBlockInKChunk(chunk: Uint8Array, x: number, y: number, z: number): Block {
    let index = x | (z << 8) | (y << 16)
    return chunk[index]
}

export function setBlockInKChunk(chunk: Uint8Array, x: number, y: number, z: number, b: Block) {
    let index = x | (z << 8) | (y << 16)
    chunk[index] = b
}

function setBlockImageData(image: ImageData, color: number[], x: number, y: number) {
    if (color === null) return
    const baseIdx = (y + x * CHUNK_SIZE) * 4
    image.data[baseIdx] = color[0]
    image.data[baseIdx + 1] = color[1]
    image.data[baseIdx + 2] = color[2]
    image.data[baseIdx + 3] = 255
}

export function fillChunkWithBlock(
    world: Engine["world"],
    request: ChunkRequest,
    block: Block,
    userData: Partial<ChunkUserData> = {}
) {
    let data = request.data

    for (let i = 0; i < data.shape[0]; i++) {
        for (let k = 0; k < data.shape[2]; k++) {
            for (let j = 0; j < data.shape[1]; j++) {
                data.set(i, j, k, block)
            }
        }
    }

    world.setChunkData(request.id, data, userData)
}

// let lastID
export function fillChunk(
    world: Engine["world"],
    request: ChunkRequest,
    chunk: Uint8Array,
    userData: Partial<ChunkUserData> = {}
) {
    let data = request.data
    let image = new ImageData(data.shape[0], data.shape[2])

    let offsetX = request.diffX * CHUNK_SIZE
    let offsetY = request.diffY * CHUNK_SIZE

    for (let i = 0; i < data.shape[0]; i++) {
        for (let k = 0; k < data.shape[2]; k++) {
            let minimapColor: number[] | null = null
            for (let j = data.shape[1] - 1; j >= 0; j--) {
                let b = getBlockInKChunk(chunk, i + offsetX, j, k + offsetY)
                // if (lastID != request.id && b == 56) {
                //     let tc = request.id.split("|")
                //     let rx = parseInt(tc[0]) * 32 + i
                //     let ry = parseInt(tc[2]) * 32 + k
                //     // @ts-ignore
                //     window.game.emit("command", new CActiveKube(rx, ry, j))
                //     lastID = request.id
                // }
                data.set(i, j, k, b)
                if (!minimapColor) minimapColor = BlocksColors[b]
            }
            if (!minimapColor) minimapColor = BlocksColors[Block.Fixed]
            setBlockImageData(image, minimapColor, k, i)
        }
    }

    world.setChunkData(request.id, data, { ...userData, minimap: image })
}

export function createRequestData(id: string, data: NdArray, x: number, y: number, z: number): ChunkRequest {
    let mX = x / CHUNK_SIZE
    let mY = z / CHUNK_SIZE

    let kX = Math.floor(mX / CHUNK_PER_KUBE_CHUNK)
    let kY = Math.floor(mY / CHUNK_PER_KUBE_CHUNK)
    let diffX = mod(mX, CHUNK_PER_KUBE_CHUNK)
    let diffY = mod(mY, CHUNK_PER_KUBE_CHUNK)

    return {
        id,
        data,
        kX,
        kY,
        diffX,
        diffY,
    }
}
