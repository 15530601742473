import { Serializer, Unserializer } from "haxeformat"
import { CodecV2 as Codec } from "mt-codec"

export function decodeData(str: string, key: string, keyPrefix?: string) {
    let codec = new Codec(key, keyPrefix)
    let decoded = codec.decode(str)

    let U = new Unserializer(decoded)
    return U.unserialize()
}

export function encodeData(obj: any, key: string, keyPrefix?: string) {
    let S = new Serializer()
    S.serialize(obj)
    let encoded = S.toString()

    let codec = new Codec(key, keyPrefix)
    return codec.encode(encoded)
}

export function parseFlashVars<T>(str: string, keyPrefix?: string): T {
    let dict = {}

    for (const el of str.split("&amp;")) {
        const key = el.split("=", 1)[0]
        dict[key] = decodeURIComponent(el.slice(key.length + 1))
    }
    if (dict["data"]) dict["data"] = decodeData(dict["data"], dict["k"], keyPrefix)
    if (dict["infos"]) dict["infos"] = decodeData(dict["infos"], dict["k"], keyPrefix)
    if (dict["d"]) dict["d"] = decodeData(dict["d"], dict["k"], keyPrefix)
    if (dict["init"]) dict["init"] = decodeData(dict["init"], dict["k"], keyPrefix)
    if (dict["texts"]) {
        let U = new Unserializer(dict["texts"])
        dict["texts"] = U.unserialize()
    }

    return dict as T
}

export function restoreFlashVars<T>(dict: T, keyPrefix?: string): string {
    let copy = { ...dict }
    if (copy["data"]) copy["data"] = encodeData(copy["data"], copy["k"], keyPrefix)
    if (copy["infos"]) copy["infos"] = encodeData(copy["infos"], copy["k"], keyPrefix)
    if (copy["d"]) copy["d"] = encodeData(copy["d"], copy["k"], keyPrefix)
    if (copy["init"]) copy["init"] = encodeData(copy["init"], copy["k"], keyPrefix)

    if (copy["texts"]) {
        let S = new Serializer()
        S.serialize(copy["texts"])
        copy["texts"] = S.toString()
    }

    let els = []
    for (const el in copy) {
        els.push(`${el}=${encodeURIComponent(copy[el].toString())}`)
    }

    return els.join("&amp;")
}
