import { CHUNK_SIZE } from "../defaultConfig"
import { GameComponent } from "../types/GameComponent"
import { Game } from "./Game"
import { ChunkUserData } from "./chunkHelper"
import vec3 from "gl-vec3"
import { Engine } from "noa-engine"
import Chunk from "noa-engine/dist/src/lib/chunk"

const memoryCanvas = document.createElement("canvas")
const memoryCanvasCtx = memoryCanvas.getContext("2d")
memoryCanvas.width = CHUNK_SIZE
memoryCanvas.height = CHUNK_SIZE

function mod(value: number, n: number) {
    return ((value % n) + n) % n
}

declare module "./Game" {
    interface GameEvents {
        registerMinimap: (canvasEl: HTMLCanvasElement) => void
    }
}

// TODO: Be able to reuse code for big map.
export class Minimap extends GameComponent {
    world: Engine["world"]
    camera: Engine["camera"]

    playerPosition: number[]

    canvasEls: HTMLCanvasElement[] = []
    scale: number = 3

    zoneDelimiterWidth = 2
    squareSize = 3
    hidePlayer = false
    clip = true

    constructor(game: Game) {
        super(game)

        this.world = game.noa.world
        this.camera = game.noa.camera
        this.playerPosition = game.noa.ents.getPosition(game.noa.playerEntity)

        this.game.on("registerMinimap", this.registerMinimap.bind(this))
    }

    registerMinimap(canvasEl: HTMLCanvasElement) {
        if (this.canvasEls.includes(canvasEl)) return
        console.log("Registering minimap", canvasEl)
        canvasEl.width = canvasEl.parentElement.clientWidth
        canvasEl.height = canvasEl.parentElement.clientHeight
        this.canvasEls.push(canvasEl)
    }

    drawChunkInMemoryCanvas(chunkPosition: number[]) {
        const chunk: Chunk | undefined = this.world._storage.getChunkByIndexes(chunkPosition[0], 0, chunkPosition[2])
        // @ts-expect-error: Type error in noa
        const userData: ChunkUserData | undefined = chunk?.userData
        if (!userData?.minimap) return false
        memoryCanvasCtx.putImageData(userData.minimap, 0, 0)
        return true
    }

    drawChunksArround(ctx: CanvasRenderingContext2D, chunkPosition: number[]) {
        ctx.fillStyle = "black"
        const draw = (offset: number[]) => {
            const drawPosition = [0, 0, 0]
            vec3.add(drawPosition, chunkPosition, offset)
            if (this.drawChunkInMemoryCanvas(drawPosition)) {
                ctx.drawImage(memoryCanvas, CHUNK_SIZE * offset[0], CHUNK_SIZE * offset[2], CHUNK_SIZE, CHUNK_SIZE)
            } else {
                ctx.fillRect(CHUNK_SIZE * offset[0], CHUNK_SIZE * offset[2], CHUNK_SIZE, CHUNK_SIZE)
            }
        }
        // Always draw a square of 3*3 chunks

        for (let x = -Math.floor(this.squareSize / 2); x < Math.ceil(this.squareSize / 2); x++) {
            for (let z = -Math.floor(this.squareSize / 2); z < Math.ceil(this.squareSize / 2); z++) {
                draw([x, 0, z])
            }
        }
    }

    drawPlayer(ctx: CanvasRenderingContext2D) {
        if (this.hidePlayer) return
        const midWidth = ctx.canvas.width / 2
        const midHeight = ctx.canvas.height / 2
        ctx.fillStyle = "white"
        ctx.fillRect(midWidth - 4, midHeight - 1, 8, 2)
        ctx.fillRect(midWidth - 1, midHeight - 4, 2, 8)
    }

    drawZoneDelimiters(ctx: CanvasRenderingContext2D) {
        ctx.strokeStyle = "white"
        ctx.setLineDash([8, 4])
        ctx.lineWidth = this.zoneDelimiterWidth

        for (let k = -Math.floor(this.squareSize / 2); k < Math.ceil(this.squareSize / 2); k++) {
            ctx.beginPath()
            ctx.moveTo(CHUNK_SIZE * this.scale * k, CHUNK_SIZE * this.scale * -Math.floor(this.squareSize / 2))
            ctx.lineTo(CHUNK_SIZE * this.scale * k, CHUNK_SIZE * this.scale * Math.ceil(this.squareSize / 2))
            ctx.stroke()

            ctx.beginPath()
            ctx.moveTo(CHUNK_SIZE * this.scale * -Math.floor(this.squareSize / 2), CHUNK_SIZE * this.scale * k)
            ctx.lineTo(CHUNK_SIZE * this.scale * Math.ceil(this.squareSize / 2), CHUNK_SIZE * this.scale * k)
            ctx.stroke()
        }
    }

    drawMinimap(ctx: CanvasRenderingContext2D) {
        ctx.save()

        if (this.clip) {
            ctx.beginPath()
            ctx.arc(ctx.canvas.width / 2, ctx.canvas.height / 2, ctx.canvas.width / 2, 0, Math.PI * 2, true)
            ctx.clip()
        }

        ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2)
        ctx.rotate(this.camera.heading + Math.PI)

        const offX = mod(this.playerPosition[0], CHUNK_SIZE)
        const offZ = mod(this.playerPosition[2], CHUNK_SIZE)
        ctx.translate(-offX * this.scale, -offZ * this.scale)

        ctx.scale(this.scale, this.scale)

        ctx.imageSmoothingEnabled = false

        const chunkPlayerPosition = this.world._coordsToChunkIndexes(
            Math.floor(this.playerPosition[0]),
            0,
            Math.floor(this.playerPosition[2])
        )
        this.drawChunksArround(ctx, chunkPlayerPosition)

        ctx.scale(1 / this.scale, 1 / this.scale)

        this.drawZoneDelimiters(ctx)

        ctx.restore()

        this.drawPlayer(ctx)
    }

    // Optimisations :
    // - Redraw only when player is moving.
    // - Only rotate (css ?) when the camera heading change

    i = 0
    update(deltaTime: number) {
        this.playerPosition = this.game.noa.ents.getPosition(this.game.noa.playerEntity)
        this.i++
        this.i += 1
        if (this.i % 2) return
        super.update(deltaTime)

        for (const canvasEl of this.canvasEls) {
            this.drawMinimap(canvasEl.getContext("2d"))
        }
    }
}
