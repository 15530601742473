import { css } from "@emotion/react"
import { Game } from "kube-engine/src/game"
import { useEffect, useRef } from "react"

const classes = {
    minimap: css`
        width: 130px;
        height: 130px;
        position: relative;
        top: 52px;
        left: 30px;
        & canvas {
            image-rendering: pixelated;
        }
    `,
}

export const Minimap = ({ game }: { game: Game }) => {
    const minimapRef = useRef()

    useEffect(() => {
        if (minimapRef?.current) {
            game.emit("registerMinimap", minimapRef.current)
        }
        return () => {
            // game.emit("unregisterMinimap", minimapRef.current)
        }
    }, [minimapRef?.current])

    return (
        <div css={classes.minimap}>
            <canvas ref={minimapRef} width="32" height="32" />
        </div>
    )
}
