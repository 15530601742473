import { Block } from "../game/Block"
import { uncompress } from "../game/chunkHelper"
import { IKubeClient } from "./IKubeClient"
import { AMap } from "./enums/_Answer"
import { _Cmd, CLoad, CWatch } from "./enums/_Cmd"
import AMF from "amf-js"
import { Buffer } from "buffer"

interface Kub3ditFormat {
    png: Buffer // raw png file (mini map)
    chunkData: Buffer // dataLen: Zlib stream
    dataLen: number // u32
    magic: ".K3D"
}

interface Kub3ditMap {
    sizeX: number // u16
    sizeY: number // u16
    sizeZ: number // u16
    bytes: Buffer
}

function parseKub3ditFile(file: Buffer): Kub3ditFormat {
    const totalSize = file.length
    const magic = file.slice(totalSize - 4, totalSize).toString()
    if (magic != ".K3D") throw new Error("This file is not a Kub3dit file")

    let dataLen = file.readUint32BE(totalSize - 8)
    return {
        png: file.slice(0, totalSize - dataLen - 8),
        chunkData: file.slice(totalSize - dataLen - 8, totalSize - 8),
        dataLen,
        magic,
    }
}

function parseKub3ditMap(data: Kub3ditFormat): Kub3ditMap {
    const uncompressed = new Buffer(uncompress(data.chunkData))
    const version = uncompressed.readUint8()
    if (1 > version || version > 3) {
        throw new Error(`Kub3dit file version ${version} is not supported`)
    }

    let position = 1
    if (version === 2 || version === 3) {
        const nbCustomKubes = uncompressed.readUint8(position++)
        if (nbCustomKubes !== 0) console.warn("Kub3dit: Skipping custom kubes, chunk data may be corrupted")
        for (let i = 0; i < nbCustomKubes; i++) {
            const xmlLength = uncompressed.readInt16BE(position)
            position += 2
            position += xmlLength
        }
        // TODO: Re-use that data to set spawn ?
        const cameraPosX = uncompressed.readInt16BE(position)
        position += 2
        const cameraPosY = uncompressed.readInt16BE(position)
        position += 2
        const cameraPosZ = uncompressed.readInt16BE(position)
        position += 2
        const cameraRotX = uncompressed.readInt32BE(position)
        position += 4
        const cameraRotY = uncompressed.readUint32BE(position)
        position += 4
    }
    if (version === 3) {
        let amfDeserializer: AMF.Deserializer = new AMF.Deserializer(uncompressed.buffer)
        amfDeserializer.pos = position
        const cameraPathObj: any[] = amfDeserializer.deserialize()
        position = amfDeserializer.pos
        if (cameraPathObj.length != 0) {
            console.warn("Kub3dit: Camera path are not supported!")
        }
    }

    const sizeX = uncompressed.readInt16BE(position)
    position += 2
    const sizeY = uncompressed.readInt16BE(position)
    position += 2
    const sizeZ = uncompressed.readInt16BE(position)
    position += 2
    const mapData = uncompressed.slice(position)

    return {
        sizeX,
        sizeY,
        sizeZ,
        bytes: mapData,
    }
}

export class Kub3ditKubeClient extends IKubeClient {
    file: Kub3ditFormat
    map: Kub3ditMap

    constructor(file: Buffer) {
        super(true)
        this.file = parseKub3ditFile(file)
        this.map = parseKub3ditMap(this.file)
        console.log(this.file, this.map)
    }

    getKChunkFromKub3ditMap(mx: number, my: number): Buffer {
        const chunk = Buffer.alloc(256 * 256 * 32, Block.Empty)
        if (mx < 0 || my < 0) return chunk
        // Kube coords, z = height
        for (let x = 0; x < 256; x++) {
            for (let y = 0; y < 256; y++) {
                for (let z = 0; z < 32; z++) {
                    const x1 = x + mx * 256
                    const y1 = y + my * 256
                    if (x1 >= this.map.sizeX || y1 >= this.map.sizeY) continue
                    if (z === 0) {
                        chunk[x | (y << 8) | (z << 16)] = Block.Water
                        continue
                    }
                    chunk[x | (y << 8) | (z << 16)] =
                        this.map.bytes[x1 + y1 * this.map.sizeX + (z - 1) * this.map.sizeX * this.map.sizeY]
                }
            }
        }
        return chunk
    }

    // IKubeClient interface
    sendCommand(cmd: _Cmd) {
        if (cmd instanceof CWatch || cmd instanceof CLoad) {
            this.game.emit(
                "answer",
                new AMap(cmd.mx, cmd.my, this.getKChunkFromKub3ditMap(cmd.mx, cmd.my), null, new Array(64).fill(null))
            )
        }
        this.localHelperSendCommand(cmd)
    }
}
