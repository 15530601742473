import { App } from "./App"
import { Block } from "kube-engine/src/game/Block"
import { ConsoleHelper } from "kube-engine/src/misc"
import * as _Answer from "kube-engine/src/network/enums/_Answer"
import * as _Cmd from "kube-engine/src/network/enums/_Cmd"
import * as _GO from "kube-engine/src/network/enums/_GO"
import ReactDOMClient from "react-dom/client"

// @ts-ignore
window._GO = _GO
// @ts-ignore
window._Cmd = _Cmd
// @ts-ignore
window._Answer = _Answer
// @ts-ignore
window.Block = Block
// @ts-ignore
window.helpers = new ConsoleHelper()

const app = document.getElementById("app")
ReactDOMClient.createRoot(app).render(<App />)
