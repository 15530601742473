import kubeGoLeft from "../img/game/kube_goleft.jpg"
import kubeGoLeftPressed from "../img/game/kube_goleft_pressed.jpg"
import kubeGoRight from "../img/game/kube_goright.jpg"
import kubeGoRightPressed from "../img/game/kube_goright_pressed.jpg"
import kubeLeft from "../img/game/kube_left.png"
import kubePhoto from "../img/game/kube_photo.png"
import kubePhotoPressed from "../img/game/kube_photo_pressed.png"
import kubeTouch from "../img/game/kube_touch.png"
import kubeTouchPressed from "../img/game/kube_touch_pressed.jpg"
import { Inventory } from "./Inventory"
import { classes as invClasses } from "./Inventory"
import { css } from "@emotion/react"
import { Game } from "kube-engine/src/game"
import { useEffect, useState } from "react"

const classes = {
    left: css`
        background: url(${kubeLeft});
        width: 196px;
        height: 320px;

        user-select: none;
    `,
    touch: css`
        background-image: url(${kubeTouch});
        width: 46px;
        height: 45px;
        position: absolute;
        top: 6px;
        left: 5px;

        &:active {
            background-image: url(${kubeTouchPressed});
        }
    `,
    photo: css`
        background-image: url(${kubePhoto});
        width: 46px;
        height: 45px;
        position: absolute;
        top: 6px;
        left: 54px;

        &:active {
            background-image: url(${kubePhotoPressed});
        }
    `,
    goleft: css`
        background-image: url(${kubeGoLeft});
        width: 96px;
        height: 45px;
        position: absolute;
        bottom: 4px;
        left: 4px;

        &:active {
            background-image: url(${kubeGoLeftPressed});
        }
    `,
    goright: css`
        background-image: url(${kubeGoRight});
        width: 96px;
        height: 45px;
        position: absolute;
        bottom: 4px;
        right: 0px;

        &:active {
            background-image: url(${kubeGoRightPressed});
        }
    `,
}

export const LeftContainer = ({ game }: { game: Game }) => {
    const [selected, setSelected] = useState<number | null>(null)
    const [page, setPage] = useState<number>(0)

    useEffect(() => {
        game.on("selectBlock", setSelected)
        return () => {
            game.off("selectBlock", setSelected)
        }
    }, [game])

    const onSelectTouch = () => {
        game.emit("selectBlock", null)
    }
    const onTakePhoto = () => {
        game.emit("takePhoto")
    }

    return (
        <div css={classes.left}>
            <div css={[classes.touch, !selected && invClasses.invSelected]} onClick={onSelectTouch} />
            <div css={classes.photo} onClick={onTakePhoto} />
            <Inventory game={game} selected={selected} page={page} />
            <div css={classes.goleft} onClick={() => setPage(Math.max(0, page - 1))} />
            <div css={classes.goright} onClick={() => setPage(page + 1)} />
        </div>
    )
}
