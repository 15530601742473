import { Game } from "../game/Game"
import { ABlocks } from "./enums/_Answer"
import { _Cmd, CSetBlocks } from "./enums/_Cmd"

// Abstraction for client/server communication
export abstract class IKubeClient {
    game: Game

    isChunksCompressed: boolean

    protected constructor(skipZlib?: boolean) {
        this.isChunksCompressed = !skipZlib
    }

    protected localHelperSendCommand(cmd: _Cmd) {
        if (cmd instanceof CSetBlocks) {
            const arr = Array(Math.floor(cmd.data.length / 5)).fill(null)
            this.game.emit("answer", new ABlocks(arr))
        }
    }

    /**
     * Just send a command.
     * @param cmd
     */
    public sendCommand(cmd: _Cmd): void {
        throw new Error("sendCommand must be implemented!")
    }

    public handleChunkRemoved(mx: number, my: number): void {}
}
