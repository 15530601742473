import styled from "@emotion/styled"

export const KubeButton = styled.button`
    display: block;
    margin: auto;

    color: #fff;
    background-color: #062f5b;
    font-weight: bold;
    border: 1px solid #446f9d;
    width: 150px;
    line-height: 20pt;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    &:hover {
        background-color: #205b99;
    }
`
