export const FRENCH_TEXTS = {
    inv_full: "Vous avez atteint le maximum de kubes de ce type que vous pouvez transporter.",
    saving_photo: "Sauvegarde de la photo en cours...",
    software_mode: "Accélération 3D Désactivée !",
    tuto_swim:
        "Quand vous vous déplacez dans l'eau, votre <b>endurance</b> (barre bleue qui remplace la barre d'énergie) diminue. Nagez un petit peu sans vous éloigner trop de la rive.",
    cant_edit_zone: "Vous ne pouvez pas modifier cette zone car son propriétaire ne vous y a pas autorisé !",
    tuto_look:
        "La <b>souris</b> a une autre utilité : vous pouvez l'utiliser pour regarder en haut et en bas. Cliquez sur l'espace de jeu, et - tout en maintenant le bouton de la souris enfoncé - déplacez la souris pour regarder autour de vous.",
    kube_names:
        "\r\nBéton\r\nEau\r\nTerre Brune\r\nBois Bouleau\r\nFeuilles Bouleau\r\nTerre d'Automne\r\nBois d'Automne\r\nFeuilles d'Automne\r\nTerre Sombre\r\nBois Sombre\r\nFeuilles Sombres\r\nHerbes Sombres\r\nTerre Bleue\r\nCoeur de Nuage\r\nNuage\r\nBois Clair\r\nFeuilles Roses\r\nRacines\r\nBois Géant\r\nFeuilles Géantes\r\nTerre-Neige\r\nBois de Sapin\r\nFeuilles-Neige\r\nJungle\r\nLianes Sombres\r\nLianes Claires\r\nLianes Pourries\r\nMur\r\nRoc\r\nPilier\r\nPrairie\r\nHerbes Claires\r\nHerbes\r\nMarais\r\nBois Marais\r\nFeuilles Marais\r\nBoue\r\nRoche Froide\r\nRoche Volcanique\r\nCendres\r\nGrès\r\nTotem Antique\r\nPâturages\r\nFleur Blanche\r\nFleur Rose\r\nStonehenge\r\nSavane\r\nArbuste Sec\r\nZèbre\r\nEléphant\r\nSable\r\nCactus\r\nGland\r\nLave\r\nSombre\r\nDolmen\r\nMeka\r\nMétal\r\nAcier\r\nKoala\r\nInvisible\r\nAméthyste\r\nEmeraude\r\nRubis\r\nSaphir\r\nBrouillard\r\nOmbre\r\nLumière\r\nOr\r\nForum\r\nTéléport\r\nRessort\r\nCoffre\r\nRubix\r\nMotionTwin\r\nCaisse\r\nKube de Noël\r\n\t",
    tuto_move:
        "Bienvenue sur <b>Kube</b> ! Pour vous déplacer dans le jeu, utilisez les flèches directionnelles de votre clavier. Essayez d'avancer avec la flèche vers le <b>HAUT</b> et de reculer avec la flèche vers le <b>BAS</b>.",
    take_fixed_block: "Vous ne pouvez pas ramasser un kube béton si vous ne louez pas cette zone.",
    put_fixed_block: "Vous ne pouvez pas poser un kube béton s'il ne touche pas un autre kube béton.",
    server_wait: "Attente de réponse du serveur...",
    tuto_jump:
        "Une touche très importante est la touche <b>Espace</b>. Elle vous permettra d'effectuer des <b>sauts</b> et d'accéder à différents endroits. Sautez plusieurs fois pour tester.",
    tuto_water_out:
        "Sortez maintenant de l'eau : pour cela il vous faudra <b>sauter</b> sur la terre ferme à partir d'une rive accessible.",
    zone_admin: "<em>Zone Publique</em>",
    tuto_unselect:
        "Pour pouvoir à nouveau ramasser des <b>kubes</b>, cliquez sur l'îcone en <b>forme de main</b> au dessus de votre inventaire.",
    tuto_put: "Utilisez à nouveau la <b>souris</b> pour poser <b>3 kubes</b> à l'emplacement que vous voulez.",
    tuto_dolmen:
        'Les objectifs de Kube sont multiples : de façon à compléter votre <a href="/map">Carte</a> vous devez trouver un <b>dolmen</b> en pierre noire et le toucher.',
    inv_empty: "Vous n'avez plus d'exemplaire de ce kube dans votre inventaire.",
    tuto_zone:
        "En dessous de votre barre d'énergie se trouve la <b>carte</b>. Les pointillés sur la carte délimitent les différentes <b>zones</b>. En dessous de la carte se trouvent les informations sur la <b>zone actuelle</b>. Déplacez-vous sur une zone voisine.",
    tuto_energy:
        "Vous n'avez plus d'énergie pour aujourd'hui ! Vous pouvez au choix revenir sur Kube demain pour récuperer votre énergie ou obtenir de l'énergie immédiatement via la <a href=\"/shop\">Boutique</a>.",
    fixed_block: "Vous ne pouvez pas poser un kube par dessus un kube béton.",
    photo_save_delay: "La photo a été prise : cliquez à nouveau sur l'appareil photo pour l'enregistrer !",
    infos: "http://kube.muxxu.com/zone/infos/::zx::.::zy::",
    hide_photo_off: "Mode photo avec position cachée : désactivé",
    cant_put_on_water: "Vous ne pouvez pas poser un kube sur l'eau si loin de la rive.",
    cant_teleport: "Aucun point d'arrivée n'a été trouvé à proximité de ce télétransporteur.",
    hide_photo_on: "Mode photo avec position cachée : activé",
    cannot_act: "Vous ne pouvez plus effectuer d'action pour aujourd'hui car vous n'avez plus d'énergie...",
    zone_user: "Louée par <b>::u::</b>",
    tuto_take:
        "Passons maintenant à la <b>souris</b>. Le monde est composé de <b>kubes</b> que vous pouvez ramasser en cliquant dessus à condition qu'ils soient proches. Ramassez <b>trois kubes</b> grâce à la souris en vous rapprochant si nécessaire.",
    tuto_zone_pout:
        "Sur une zone réservée, vous ne pouvez ramasser ou déposer des <b>kubes</b> que si le joueur locataire de la zone vous y a autorisé. Retournez maintenant sur une <b>zone publique</b>.",
    taking_photo: "Prise de la photo...",
    tuto_kubes:
        "Les objectifs de Kube sont multiples : il existe de très nombreux <b>kubes</b>. Essayez d'en ramasser <b>quatre types</b> différents en explorant le monde de Kube ! (vous pouvez aussi utiliser le D.T.C via la Boutique pour changer de zone publique)",
    tuto_turn:
        "Vous pouvez aussi <b>tourner</b> en vous servant des flèches <b>GAUCHE</b> et <b>DROITE</b>. Essayez de regarder autour de vous sans bouger.",
    tuto_end:
        "Félicitations ! Vous avez fini d'apprendre les bases de Kube mais il vous reste de nombreux mystères à découvrir. Vous êtes maintenant libre d'aller où vous voulez, d'explorer, de collectionner les kubes, de compléter votre carte, et bien entendu de créer seul ou en groupe de magnifiques créations !",
    loading_world: "Chargement du Monde...",
    tuto_select:
        "Une fois les <b>kubes</b> ramassés, vous pouvez les <b>poser</b>. Sélectionnez un kube que vous avez ramassé à partir de votre <b>inventaire</b> à gauche.",
    zone_empty: "<em>Non habitée</em>",
    disconnect: "Vous avez été déconnecté...",
    tuto_cost:
        "<b>Important !</b> à chaque fois que vous prenez ou posez un Kube, votre énergie (barre blanche en haut à droite) diminue. Quand vous n'avez plus d'énergie, vous devrez attendre le lendemain avant de pouvoir vous déplacer à nouveau. Déplacez vous un peu.",
    tuto_water:
        "L'un des dangers que vous rencontrerez au cours de vos explorations sera l'eau : allez plonger à un endroit où il y a de l'eau",
    tuto_zone_p:
        "Les zones libres peuvent être réservées par les joueurs. Déplacez-vous sur une zone qui a été réservée par un autre joueur.",
    tuto_explore:
        "Les objectifs de Kube sont multiples : rejoignez une zone dont l'une des coordonnées atteignent [-10] ou [+10].",
}
