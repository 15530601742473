import { AMap, _Answer, AGenerate } from "../network/enums/_Answer"
import { CLoad, CWatch } from "../network/enums/_Cmd"
import { GameComponent } from "../types/GameComponent"
import { Block } from "./Block"
import { Game } from "./Game"
import { applyPatches, createRequestData, fillChunk, fillChunkWithBlock, KChunkData, uncompress } from "./chunkHelper"
import { NdArray } from "ndarray"
import { Engine } from "noa-engine"

export class ChunkProvider extends GameComponent {
    world: Engine["world"]

    loadedChunks: KChunkData[] = []

    constructor(game: Game) {
        super(game)

        this.world = game.noa.world

        this.world.on("worldDataNeeded", this.onWorldDataNeeded.bind(this))
        this.world.on("chunkBeingRemoved", this.onChunkBeingRemoved.bind(this))
        this.game.on("answer", this.processAnswer.bind(this))
    }

    public onWorldDataNeeded(id: string, data: NdArray, x: number, y: number, z: number) {
        let rData = createRequestData(id, data, x, y, z)
        if (y < 0 || y >= 32) return fillChunkWithBlock(this.world, rData, Block.Empty)

        let chunk = this.loadedChunks.find((c) => c.kX === rData.kX && c.kY === rData.kY)
        if (chunk) {
            chunk.loadedChunks.add(id)
            if (chunk.data)
                // Chunk already ready
                fillChunk(this.world, rData, chunk.data, {
                    zone: chunk.zones?.[rData.diffX * 8 + rData.diffY],
                    requestSize: chunk.requestSize,
                })
            else chunk.requested.push(rData)
            return
        }
        // Chunk never asked
        let cmd = new (this.game.config.useLoadInsteandOfWatch ? CLoad : CWatch)(rData.kX, rData.kY)

        this.loadedChunks.push({
            kX: rData.kX,
            kY: rData.kY,
            loadedChunks: new Set<string>([id]),
            requested: [rData],
        })

        this.game.emit("command", cmd)
    }

    public onChunkBeingRemoved(id: string, data: NdArray, userData: any) {
        let chunkIdx = this.loadedChunks.findIndex((c) => c.loadedChunks.has(id))
        if (chunkIdx === -1) return

        let chunk = this.loadedChunks[chunkIdx]
        chunk.loadedChunks.delete(id)
        if (chunk.loadedChunks.size === 0) {
            this.game.client.handleChunkRemoved(chunk.kX, chunk.kY)
            this.loadedChunks.splice(chunkIdx, 1)
        }
    }

    public processAnswer(answer: _Answer, answerSize: number) {
        if (answer instanceof AMap) {
            let chunk = this.loadedChunks.find((c) => c.kX === answer.mx && c.kY === answer.my)
            if (!chunk) return console.error("Received AMap but chunk not requested")
            // TODO: We should use a very fast compression algo to compress chunks, to reduce memory usage.
            chunk.data = this.game.client.isChunksCompressed ? uncompress(answer.data) : answer.data
            applyPatches(chunk.data, answer.patches)
            chunk.zones = answer.zones
            chunk.requestSize = answerSize

            for (const rData of chunk.requested)
                fillChunk(this.world, rData, chunk.data, {
                    zone: chunk.zones?.[rData.diffX * 8 + rData.diffY],
                    requestSize: chunk.requestSize,
                })
            chunk.requested = []
        } else if (answer instanceof AGenerate) {
            // If the server ask a chunk generation, do not respond and fill chunk with BFixed
            let chunk = this.loadedChunks.find((c) => c.kX === answer.mx && c.kY === answer.my)
            if (!chunk) return console.error("Received AGenerate but chunk not requested")
            chunk.data = new Uint8Array(Array(256 * 256 * 32).fill(Block.Fixed))

            for (const rData of chunk.requested) fillChunk(this.world, rData, chunk.data, {})
            chunk.requested = []
        }
    }
}
