const _temp0 = require("./collideEntities.js");
const _temp1 = require("./collideTerrain.js");
const _temp2 = require("./fadeOnZoom.js");
const _temp3 = require("./followsEntity.js");
const _temp4 = require("./mesh.js");
const _temp5 = require("./movement.js");
const _temp6 = require("./physics.js");
const _temp7 = require("./position.js");
const _temp8 = require("./receivesInputs.js");
const _temp9 = require("./shadow.js");
const _temp10 = require("./smoothCamera.js");
module.exports = {
  "collideEntities": _temp0,
  "collideTerrain": _temp1,
  "fadeOnZoom": _temp2,
  "followsEntity": _temp3,
  "mesh": _temp4,
  "movement": _temp5,
  "physics": _temp6,
  "position": _temp7,
  "receivesInputs": _temp8,
  "shadow": _temp9,
  "smoothCamera": _temp10
}