export enum HaxeType {
    TNull,
    TInt,
    TFloat,
    TBool,
    TObject,
    TFunction,
    TClass,
    TEnum,
    TUnknown,
}
