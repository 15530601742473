import { IKubeClient } from "./IKubeClient"
import { AMap } from "./enums/_Answer"
import { _Cmd, CLoad, CWatch } from "./enums/_Cmd"
import { Buffer } from "buffer"
import { Block } from "../game/Block"

export class LocalGeneratorKubeClient extends IKubeClient {
    constructor() {
        super(true)
    }

    generateChunk(mx: number, my: number) {
        console.log("Générate chunk ", mx, my)
        let buffer = new Buffer(256 * 256 * 32)
        for (let x = 0; x < 256; x++) {
            for (let y = 0; y < 256; y++) {
                buffer[x + y * 256] = Block.Water
            }
        }
        for (let x = 0; x < 256; x++) {
            for (let y = 0; y < 256; y++) {
                if (x % 8 == 0 && y % 8 == 0) {
                    const z = 1;
                    buffer[x + y * 256 + z * 256 * 256] = Math.floor(Math.random() * 76) + 1
                }
            }
        }
        return buffer
    }

    // IKubeClient interface
    sendCommand(cmd: _Cmd) {
        console.log("Send command local", cmd)
        if (cmd instanceof CWatch || cmd instanceof CLoad) {
            this.game.emit(
                "answer",
                new AMap(cmd.mx, cmd.my, this.generateChunk(cmd.mx, cmd.my), null, new Array(64).fill(null))
            )
        }
        this.localHelperSendCommand(cmd)
    }
}
