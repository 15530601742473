import { FRENCH_TEXTS } from "../misc/texts"
import { KubeDataFlashVars } from "../types/FlashVars"
import { GameComponent } from "../types/GameComponent"
import { Block, isTouchable } from "./Block"
import { Game } from "./Game"

declare module "./Game" {
    interface GameEvents {
        selectBlock: (b: Block | null) => void

        uiPower: (pow: number, swim: number | null) => void
        uiInventory: (inventory: (number | null)[]) => void
    }
}

// TODO: Rename that
export class PlayerData extends GameComponent {
    inventoryMax: number

    inventory: (number | null)[] = []
    power: number
    swim: number | null

    selectedBlock: Block = Block.Empty

    constructor(game: Game, dataFlashVars?: Partial<KubeDataFlashVars>) {
        super(game)

        this.power = dataFlashVars?._pow ?? 10000
        this.swim = dataFlashVars?._swim ?? null
        this.inventory = dataFlashVars?._inv ?? [null, ...new Array(76).fill(50)]

        this.inventoryMax = dataFlashVars?._imax ?? 100

        this.setupInputs()

        this.game.on("selectBlock", this.onSelectBlock.bind(this))
    }

    onSelectBlock(b: Block | null) {
        this.selectedBlock = b ?? Block.Empty
    }

    tryTakeBlock(b: Block) {
        if (isTouchable(b) && !this.config.pickEveryBlock) return true
        if (this.power <= 0) {
            this.game.emit("uiMessage", FRENCH_TEXTS["cannot_act"], true)
            return false
        }
        if (this.inventory[b] >= this.inventoryMax) {
            this.game.emit("uiMessage", FRENCH_TEXTS["inv_full"], true)
            return false
        }

        return true
    }

    tryPutBlock(b: Block) {
        if (this.power <= 0) {
            this.game.emit("uiMessage", FRENCH_TEXTS["cannot_act"], true)
            return false
        }
        if (this.inventory[b] <= 0) {
            this.game.emit("uiMessage", FRENCH_TEXTS["inv_empty"], true)
            return false
        }

        return true
    }

    updateInventory(b: Block, diff: number, isUndo?: boolean) {
        this.inventory[b] += diff
        this.power += isUndo ? 80 : -80

        this.game.emit("uiPower", this.power, this.swim)
        this.game.emit("uiInventory", [...this.inventory])
    }

    //region Inputs
    checkBounds(pos: number[]) {
        return 0 < pos[1] && pos[1] < 32
    }

    setupInputs() {
        let self = this

        let fireEv = null
        this.game.noa.inputs.down.on("fire", function (ev: MouseEvent) {
            fireEv = ev
        })
        this.game.noa.inputs.up.on("fire", function (ev: MouseEvent) {
            // FIXME: Do not move the camera in the 5 px delta
            if (
                self.game.noa.targetedBlock &&
                ev.timeStamp - fireEv.timeStamp < 150 && // ms
                Math.abs(fireEv.clientX - ev.clientX) < 3 &&
                Math.abs(fireEv.clientY - ev.clientY) < 3
            ) {
                let pos = self.game.noa.targetedBlock.position
                let b = self.game.noa.getBlock(pos)
                if (self.checkBounds(pos) && self.tryTakeBlock(b)) {
                    self.game.setBlock(0, pos)
                }
            }
        })

        // place some grass on right click
        let fireAltEv = null
        this.game.noa.inputs.down.on("alt-fire", function (ev: MouseEvent) {
            fireAltEv = ev
        })
        this.game.noa.inputs.up.on("alt-fire", function (ev: MouseEvent) {
            if (
                self.game.noa.targetedBlock &&
                ev.timeStamp - fireAltEv.timeStamp < 150 && // ms
                Math.abs(fireAltEv.clientX - ev.clientX) < 3 &&
                Math.abs(fireAltEv.clientY - ev.clientY) < 3
            ) {
                let pos = self.game.noa.targetedBlock.adjacent
                if (self.checkBounds(pos) && self.tryPutBlock(self.selectedBlock)) {
                    self.game.setBlock(self.selectedBlock, pos)
                }
            }
        })
    }
    //endregion
}
