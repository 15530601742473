import { css } from "@emotion/react"
import { ChunkUserData, Game } from "kube-engine/src/game"
import { useCallback, useEffect, useState } from "react"

const classes = {
    message: css`
        position: absolute;
        bottom: 30px;
        left: 20px;
        width: 150px;
        height: 100px;

        font-size: small;
        color: #dbdbdb;

        & a {
            color: SkyBlue;
        }
    `,
    messageError: css`
        font-weight: bold;
        color: #ff2424;
    `,
    messageSuccess: css`
        font-weight: bold;
        color: lightgreen;
    `,
}

export const ZoneInfoBox = ({ game }: { game: Game }) => {
    const [message, setMessage] = useState<{ text: string; isError: boolean; timeout: NodeJS.Timeout } | null>(null)
    const [zoneInfo, setZoneInfo] = useState<{ mx: number; my: number; data: ChunkUserData | undefined }>(() => {
        const chunk = game.getPlayerChunk()
        return {
            mx: chunk?.x ?? 0,
            my: chunk?.z ?? 0,
            // @ts-ignore
            data: chunk?.userData,
        }
    })

    const msgCallback = useCallback((text: string, isError?: boolean, isFatal?: boolean) => {
        setMessage((old) => {
            if (old) clearTimeout(old.timeout)
            const timeout = setTimeout(() => setMessage(null), 4000)
            return { text, isError: !!isError, timeout }
        })
    }, [])
    const playerEnterZoneCallback = useCallback((mx: number, my: number, data: ChunkUserData) => {
        setZoneInfo({ mx, my, data })
    }, [])

    useEffect(() => {
        game.on("uiMessage", msgCallback)
        game.on("uiPlayerEnterZone", playerEnterZoneCallback)
        return () => {
            game.off("uiMessage", msgCallback)
            game.off("uiPlayerEnterZone", playerEnterZoneCallback)
        }
    }, [])

    return (
        <div css={classes.message}>
            {message ? (
                <div css={message.isError ? classes.messageError : classes.messageSuccess}>{message.text}</div>
            ) : (
                <>
                    <div>
                        Zone [{zoneInfo.mx}][{zoneInfo.my}]
                    </div>
                    {zoneInfo.data?.zone ? (
                        zoneInfo.data?.zone?._u === null ? (
                            <div>Zone publique</div>
                        ) : (
                            <div>Zone habitée</div>
                        )
                    ) : (
                        <div>Zone non habitée</div>
                    )}
                    <div>{zoneInfo.data?.zone?._u}</div>
                    <div>{zoneInfo.data?.zone?._n}</div>
                    <div>{zoneInfo.data?.zone?._d?.toISOString()}</div>
                    <div>Prise : {zoneInfo.data?.zone?._g ? "V" : "X"}</div>
                    <div>Pose : {zoneInfo.data?.zone?._p ? "V" : "X"}</div>
                </>
            )}
        </div>
    )
}
