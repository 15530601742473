export enum Code {
    CFile = 1,
    CUri,
    CClientIP,
    CGetParams,
    CPostData,
    CHeaderKey,
    CHeaderValue,
    CHeaderAddValue,
    CParamKey,
    CParamValue,
    CHostName,
    CHttpMethod,
    CExecute,
    CError,
    CPrint,
    CLog,
    CFlush,
    CRedirect,
    CReturnCode,
    CQueryMultipart,
    CPartFilename,
    CPartKey,
    CPartData,
    CPartDone,
    CTestConnect,
    CListen,
    CHostResolve,
}
