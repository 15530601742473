import { _GO } from "./_GO"
import { Buffer } from "buffer"
import { HaxeEnum } from "haxeformat"

export class _Cmd extends HaxeEnum {
    static readonly enum = "_Cmd"

    static getEnumConstructs() {
        return [
            CWatch,
            CGenLevel,
            CSetBlocks,
            CSavePos,
            CAdminDelete,
            CLoad,
            CRegen,
            CGameOver,
            CDoCheck,
            CDropKubes,
            CPing,
            CSavePhoto,
            CActiveKube,
            CUpdateTuto,
            CGetStatus,
            CTeleport,
            CUndo,
            CCountBlocks,
            CFix,
        ]
    }
}

export class CWatch extends _Cmd {
    static readonly tag = "CWatch"

    mx: number // Haxe use this to choose between int/float : Math.ceil(v) == v % 2147483648.0
    my: number

    constructor(mx: number, my: number) {
        super()
        this.mx = mx
        this.my = my
    }

    getParams() {
        return [this.mx, this.my]
    }
}

export class CGenLevel extends _Cmd {
    static readonly tag = "CGenLevel"

    mx: number
    my: number
    data: Buffer

    constructor(mx: number, my: number) {
        super()
        this.mx = mx
        this.my = my
    }

    getParams() {
        return [this.mx, this.my]
    }
}

export class CSetBlocks extends _Cmd {
    static readonly tag = "CSetBlocks"

    data: number[] // TODO: Check if it works

    constructor(data: number[]) {
        super()
        this.data = data
    }

    getParams() {
        return [this.data]
    }
}

export class CSavePos extends _Cmd {
    static readonly tag = "CSavePos"

    x: number
    y: number
    z: number
    s: number | null

    constructor(x: number, y: number, z: number, s: number | null) {
        super()
        this.x = x
        this.y = y
        this.z = z
        this.s = s
    }

    getParams(): any[] {
        return [this.x, this.y, this.z, this.s]
    }
}

export class CAdminDelete extends _Cmd {
    static readonly tag = "CAdminDelete"

    mx: number
    my: number

    constructor(mx: number, my: number) {
        super()
        this.mx = mx
        this.my = my
    }

    getParams() {
        return [this.mx, this.my]
    }
}

export class CLoad extends _Cmd {
    static readonly tag = "CLoad"

    mx: number
    my: number

    constructor(mx: number, my: number) {
        super()
        this.mx = mx
        this.my = my
    }

    getParams() {
        return [this.mx, this.my]
    }
}

export class CRegen extends _Cmd {
    static readonly tag = "CRegen"

    u: number
    zx: number
    zy: number
    block: number

    constructor(u: number, zx: number, zy: number, block: number) {
        super()
        this.u = u
        this.zx = zx
        this.zy = zy
        this.block = block
    }

    getParams() {
        return [this.u, this.zx, this.zy, this.block]
    }
}

export class CGameOver extends _Cmd {
    static readonly tag = "CGameOver"

    g: _GO

    constructor(g: _GO) {
        super()
        this.g = g
    }

    getParams() {
        return [this.g]
    }
}

export class CDoCheck extends _Cmd {
    static readonly tag = "CDoCheck"

    px: number
    py: number
    pz: number

    constructor(px: number, py: number, pz: number) {
        super()
        this.px = px
        this.py = py
        this.pz = pz
    }

    getParams() {
        return [this.px, this.py, this.pz]
    }
}

export class CDropKubes extends _Cmd {
    static readonly tag = "CDropKubes"

    zx1: number
    zy1: number
    zx2: number
    zy2: number
    qty: number
    k: number
    water: boolean
    mine: boolean

    constructor(
        zx1: number,
        zy1: number,
        zx2: number,
        zy2: number,
        qty: number,
        k: number,
        water: boolean,
        mine: boolean
    ) {
        super()
        this.zx1 = zx1
        this.zy1 = zy1
        this.zx2 = zx2
        this.zy2 = zy2
        this.qty = qty
        this.k = k
        this.water = water
        this.mine = mine
    }

    getParams() {
        return [this.zx1, this.zy1, this.zx2, this.zy2, this.qty, this.k, this.water, this.mine]
    }
}

export class CPing extends _Cmd {
    static readonly tag = "CPing"

    i: number

    constructor(i: number) {
        super()
        this.i = i
    }

    getParams() {
        return [this.i]
    }
}

export class CSavePhoto extends _Cmd {
    static readonly tag = "CSavePhoto"

    zx: number
    zy: number
    big: string
    small: string
    hide: boolean

    constructor(zx: number, zy: number, big: string, small: string, hide: boolean) {
        super()
        this.zx = zx
        this.zy = zy
        this.big = big
        this.small = small
        this.hide = hide
    }

    getParams() {
        return [this.zx, this.zy, this.big, this.small, this.hide]
    }
}

export class CActiveKube extends _Cmd {
    static readonly tag = "CActiveKube"

    x: number
    y: number
    z: number

    constructor(x: number, y: number, z: number) {
        super()
        this.x = x
        this.y = y
        this.z = z
    }

    getParams() {
        return [this.x, this.y, this.z]
    }
}

export class CUpdateTuto extends _Cmd {
    static readonly tag = "CUpdateTuto"

    v: number

    constructor(v: number) {
        super()
        this.v = v
    }

    getParams() {
        return [this.v]
    }
}

export class CGetStatus extends _Cmd {
    static readonly tag = "CGetStatus"

    getParams(): any[] {
        return []
    }
}

export class CTeleport extends _Cmd {
    static readonly tag = "CTeleport"

    x: number
    y: number
    z: number
    s: number | null

    constructor(x: number, y: number, z: number, s: number | null) {
        super()
        this.x = x
        this.y = y
        this.z = z
        this.s = s
    }

    getParams() {
        return [this.x, this.y, this.z, this.s]
    }
}

export class CUndo extends _Cmd {
    static readonly tag = "CUndo"

    getParams(): any[] {
        return []
    }
}

export class CCountBlocks extends _Cmd {
    static readonly tag = "CCountBlocks"

    zu: number
    zx: number
    zy: number

    constructor(zu: number, zx: number, zy: number) {
        super()
        this.zu = zu
        this.zx = zx
        this.zy = zy
    }

    getParams() {
        return [this.zu, this.zx, this.zy]
    }
}

export class CFix extends _Cmd {
    static readonly tag = "CFix"

    mu: number
    mx: number
    my: number

    constructor(mu: number, mx: number, my: number) {
        super()
        this.mu = mu
        this.mx = mx
        this.my = my
    }

    getParams() {
        return [this.mu, this.mx, this.my]
    }
}
