// Do not edit sizes.
export const KUBE_CHUNK_SIZE = 256
export const KUBE_CHUNK_HEIGHT = 32
export const CHUNK_SIZE = 32
export const CHUNK_PER_KUBE_CHUNK = KUBE_CHUNK_SIZE / CHUNK_SIZE

export const DEFAULT_PLAYER_SPAWN = [1, 10, 1]
export const CHUNK_ADD_DISTANCE = [6, 2] // FIXME: Tweak
export const CHUNK_REMOVE_DISTANCE = [8, 10]

export const WEBSOCKET_BRIDGE = "wss://kube-ws-bridge.thetoto.fr"

export const defaultNoaConfig = {
    debug: false,
    silent: false,
    showFPS: true,
    texturePath: "",
    tickRate: 30, // per second
    maxRenderRate: 60,

    playerHeight: 1.6,
    playerWidth: 0.3,
    playerStart: DEFAULT_PLAYER_SPAWN, // Spawn
    playerAutoStep: true, // Faster than Kube
    blockTestDistance: 10,
    sensitivityX: 80,
    sensitivityY: 80,
    inverseX: false,
    inverseY: false,

    stickyPointerLock: false, // TODO: make a "Minecraft style"
    dragCameraOutsidePointerLock: true,
    stickyFullscreen: false,
    skipDefaultHighlighting: false,
    originRebaseDistance: 25,

    chunkSize: CHUNK_SIZE,
    manuallyControlChunkLoading: false,
    chunkAddDistance: CHUNK_ADD_DISTANCE,
    chunkRemoveDistance: CHUNK_REMOVE_DISTANCE,

    gravity: [0, -25, 0],
    airDrag: 0.1,

    bindings: {
        forward: ["KeyW", "ArrowUp"],
        backward: ["KeyS", "ArrowDown"],
        left: ["KeyA", "ArrowLeft"],
        right: ["KeyD", "ArrowRight"],
        fire: "Mouse1",
        "mid-fire": ["Mouse2", "KeyQ"],
        "alt-fire": ["Mouse3", "KeyE"],
        jump: "Space",
        sprint: ["ShiftLeft", "ShiftRight"],
        crouch: ["ControlLeft", "ControlRight"],
    },
}
