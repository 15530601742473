import { css } from "@emotion/react"
import { MapGame } from "kube-engine/src/game"
import { useCallback, useEffect, useRef } from "react"

const classes = {
    minimap: css`
        width: 100vw;
        height: 100vh;
    `,
}

export const Map = ({ game, zoom }: { game: MapGame; zoom: number }) => {
    const minimapRef = useRef<HTMLCanvasElement>()

    const updateMap = useCallback(() => {
        console.log("UPDATE MAP")
        minimapRef.current.width = minimapRef.current.parentElement.clientWidth
        minimapRef.current.height = minimapRef.current.parentElement.clientHeight
        game.emit("setMapZoom", zoom, Math.max(minimapRef.current.width, minimapRef.current.height))
    }, [minimapRef?.current, zoom])

    useEffect(() => {
        updateMap()
    }, [updateMap])

    useEffect(() => {
        if (minimapRef?.current) {
            console.log("ADD EVENT LISTENER")
            window.addEventListener("resize", updateMap)
            return () => {
                console.log("REMOVE EVENT LISTENER")
                window.removeEventListener("resize", updateMap)
            }
        }
    }, [minimapRef?.current, updateMap])

    useEffect(() => {
        if (minimapRef?.current) {
            game.emit("registerMinimap", minimapRef.current)

            let pos: [number, number] | undefined = undefined
            minimapRef.current.addEventListener("mousedown", (e) => {
                pos = [e.pageX, e.pageY]
            })
            minimapRef.current.addEventListener("mousemove", (e) => {
                if (pos) {
                    const deltaX = e.pageX - pos[0]
                    const deltaY = e.pageY - pos[1]
                    game.emit("moveMap", deltaX, deltaY)
                    pos = [e.pageX, e.pageY]
                } else {
                    game.emit(
                        "moveMouse",
                        e.clientX - minimapRef.current.parentElement.clientWidth / 2,
                        e.clientY - minimapRef.current.parentElement.clientHeight / 2
                    )
                }
            })
            minimapRef.current.addEventListener("mouseup", (e) => {
                pos = undefined
            })
        }
        return () => {
            // game.emit("unregisterMinimap", minimapRef.current)
        }
    }, [minimapRef?.current])

    return (
        <div css={classes.minimap}>
            <canvas ref={minimapRef} />
        </div>
    )
}
