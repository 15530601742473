import { defaultNoaConfig } from "../defaultConfig"
import { Config } from "../misc"
import { parseFlashVars, ToraKubeClient } from "../network"
import { IKubeClient } from "../network"
import { _Answer, _GO, ABlocks, AMessage, ARedirect, ASet, ASetMany, AShowError } from "../network/enums"
import { _Cmd, CActiveKube, CSavePos, CSetBlocks, CTeleport } from "../network/enums"
import { KubeDataFlashVars, KubeFlashVars } from "../types/FlashVars"
import { Block, isTouchable, registerBlocks } from "./Block"
import { ChunkProvider } from "./ChunkProvider"
import { Minimap } from "./Minimap"
import { Photo } from "./Photo"
import { PlayerData } from "./PlayerData"
import { ChunkUserData } from "./chunkHelper"
import vec3 from "gl-vec3"
import { Unserializer } from "haxeformat/lib"
import { Engine } from "noa-engine"
import Chunk from "noa-engine/dist/src/lib/chunk"
import { ListenerSignature, TypedEmitter } from "tiny-typed-emitter"

export const KUBE_KEY_PREFIX: string = "77e352"

Unserializer.registerSerializableEnum(_Cmd)
Unserializer.registerSerializableEnum(_Answer)
Unserializer.registerSerializableEnum(_GO)

export interface GameEvents {
    // IN game events (handled by game engine)
    command: (cmd: _Cmd) => void
    answer: (ans: _Answer, answerSize?: number) => void
    clientError: (err: any) => void

    // OUT game events (only emitted by game engine, for frontend)
    uiPlayerEnterZone: (x: number, y: number, chunkData: ChunkUserData) => void
    uiMessage: (message: string, isError?: boolean, isFatal?: boolean) => void
    uiMessageKey: (messageKey: string, isError?: boolean) => void
    uiLoading: (isLoading: boolean) => void
}

export class Game extends TypedEmitter<GameEvents> {
    noa: Engine
    config: Config
    client: IKubeClient

    player: PlayerData
    minimap: Minimap
    photo: Photo
    chunkProvider: ChunkProvider

    lastSave: number[] | null = null
    isLoading: boolean = true

    setBlockCache: { x: number; y: number; z: number; old: number; new: number }[] = []

    static fromFlashVars(
        gameEl: HTMLElement,
        flashVars: string,
        websocketBridge: string | boolean,
        dataOverride?: Partial<KubeDataFlashVars>,
        noaOptionsOverride?: any
    ) {
        const dict = parseFlashVars<KubeFlashVars>(flashVars, KUBE_KEY_PREFIX)
        const server = dict.data._s.replace(".com", ".com:6767")
        const tora = new ToraKubeClient(
            dict.k,
            dict.sid,
            server,
            !!websocketBridge,
            typeof websocketBridge === "string" ? websocketBridge : undefined
        )
        return new Game(tora, { domElement: gameEl, ...noaOptionsOverride }, { ...dict.data, ...dataOverride })
    }

    static fromExistingClient(
        gameEl: HTMLElement,
        client: IKubeClient,
        dataOverride?: Partial<KubeDataFlashVars>,
        noaOptionsOverride?: any
    ) {
        return new Game(client, { domElement: gameEl, ...noaOptionsOverride }, dataOverride)
    }

    constructor(client: IKubeClient, noaOptionsOverride?: any, dataFlashVars?: Partial<KubeDataFlashVars>) {
        super()

        this.config = new Config(this)
        this.client = client
        this.client.game = this

        this.noa = new Engine({
            ...defaultNoaConfig,
            playerStart:
                dataFlashVars?._x != undefined
                    ? [dataFlashVars._x / 16, (dataFlashVars?._z ?? 1) / 16, dataFlashVars._y / 16]
                    : defaultNoaConfig.playerStart,
            ...noaOptionsOverride,
        })
        registerBlocks(this.noa)

        this.player = new PlayerData(this, dataFlashVars)
        this.minimap = new Minimap(this)
        this.photo = new Photo(this)
        this.chunkProvider = new ChunkProvider(this)

        setInterval(this.savePosition.bind(this), 500) // One per 0.5s
        this.noa.on("tick", this.update.bind(this))
        this.noa.world.on("playerEnteredChunk", this.playerEnteredChunk.bind(this))

        this.on("answer", this.onAnswer.bind(this))
        this.on("command", this.onCommand.bind(this))

        this.on("command", client.sendCommand.bind(client))
        this.on("clientError", (err) => this.emit("uiMessage", err?.message ?? err.toString(), true, true))

        console.log("Game successfully initialized")
    }

    // Called each tick
    update(deltaTime: number) {
        const position = this.noa.ents.getPosition(this.noa.playerEntity)
        const physics = this.noa.entities.getPhysics(this.noa.playerEntity)

        // Get floor block
        let res = this.noa.pick(null, [0, -1, 0], 1.6)
        // TODO: If the distance is bellow the player height, we are inside a block => teleport y + 1
        if (res) {
            let pos = [0, 0, 0]
            vec3.floor(pos, res.position)
            vec3.sub(pos, pos, res.normal)
            const ground = this.noa.getBlock(pos)
            switch (ground) {
                case Block.Jump:
                    physics.body.velocity[1] = 20
                    break
                case Block.Teleport:
                    // TODO
                    break
                case Block.Water:
                    // TODO
                    break
            }
            // Start to send save updates when we hit the ground
            if (this.lastSave === null && ground !== Block.Empty) this.lastSave = vec3.clone(position)
        }

        if (this.noa.world.playerChunkLoaded === this.isLoading) {
            this.isLoading = !this.noa.world.playerChunkLoaded

            this.emit("uiLoading", this.isLoading)
            const chunkPos = this.noa.world._coordsToChunkIndexes(position[0], position[1], position[2])
            if (!this.isLoading) this.playerEnteredChunk(chunkPos[0], chunkPos[1], chunkPos[2])
        }
    }

    savePosition() {
        const pos = this.noa.ents.getPosition(this.noa.playerEntity)
        const [x, y, z] = pos

        // Send SavePos command if distance from last save is > 15.
        if (!this.config.skipPositionUpdates && this.lastSave && vec3.dist(this.lastSave, pos) > 20) {
            this.emit(
                "command",
                new (this.config.useTeleportInsteadOfSavePos ? CTeleport : CSavePos)(
                    Math.floor(x * 16),
                    Math.floor(z * 16),
                    Math.floor(y * 16),
                    null
                )
            )
            this.lastSave = vec3.clone(pos)
        }
    }

    onAnswer(ans: _Answer) {
        if (ans instanceof AShowError) {
            this.emit("uiMessage", ans.text, true, true)
        } else if (ans instanceof ABlocks) {
            for (const block of ans.a) {
                if (this.setBlockCache.length <= 0) {
                    return console.warn("Received ABlocks without pending set block")
                }
                const cache = this.setBlockCache.shift()
                if (block === null) {
                    // Success put/take
                    this.noa.setBlock(cache.new, cache.x, cache.z, cache.y)

                    if (cache.new === Block.Empty) this.player.updateInventory(cache.old, 1, false)
                    else this.player.updateInventory(cache.new, -1, false)
                } else {
                    if (block === undefined) {
                        this.emit("uiMessage", "TODO: I ABlocks returned undefined. Don't known what to do.", true)
                    } else {
                        this.emit("uiMessage", "Try/Put blocks failed, for any reason. (rights, on water, ...)", true)
                        this.noa.setBlock(block, cache.x, cache.z, cache.y)
                    }
                }
            }
        } else if (ans instanceof ASet) {
            this.noa.setBlock(ans.k, ans.x, ans.z, ans.y)
        } else if (ans instanceof ASetMany) {
            for (let i = 0; i < ans.a.length; i += 4) {
                this.noa.setBlock(ans.a[i + 3], ans.a[i], ans.a[i + 2], ans.a[i + 1])
            }
        } else if (ans instanceof AMessage) {
            this.emit("uiMessage", ans.text, ans.error)
        } else if (ans instanceof ARedirect) {
            if (window.parent && window.parent !== window) window.parent.postMessage(`redirect:${ans.url}`, "*")
            else window.location.replace(ans.url)
        }
    }

    onCommand(cmd: _Cmd) {
        if (cmd instanceof CSetBlocks) {
            for (let i = 0; i < cmd.data.length; i += 5) {
                this.setBlockCache.push({
                    x: cmd.data[i],
                    y: cmd.data[i + 1],
                    z: cmd.data[i + 2],
                    old: cmd.data[i + 3],
                    new: cmd.data[i + 4],
                })
                if (this.config.takePutBeforeAnswer)
                    this.noa.setBlock(cmd.data[i + 4], cmd.data[i], cmd.data[i + 2], cmd.data[i + 1])
            }
        }
    }

    getPlayerChunk(): Chunk | undefined {
        const pos = this.noa.ents.getPosition(this.noa.playerEntity)
        const chunkPos = this.noa.world._coordsToChunkIndexes(pos[0], 0, pos[2])

        return this.noa.world._storage.getChunkByIndexes(chunkPos[0], chunkPos[1], chunkPos[2])
    }

    playerEnteredChunk(zx, zy, zz) {
        if (!this.noa.world.playerChunkLoaded) {
            this.isLoading = true
            this.emit("uiLoading", this.isLoading)
            return
        }
        const chunk: Chunk | undefined = this.noa.world._storage.getChunkByIndexes(zx, 0, zz)
        // @ts-expect-error: Type error in noa
        const userData: ChunkUserData | undefined = chunk?.userData
        this.emit("uiPlayerEnterZone", zx, zz, userData)
    }

    setBlock(b: Block, pos: number[]) {
        const old = this.noa.getBlock(pos)
        let cmd: _Cmd
        if (isTouchable(old) && b === Block.Empty && !this.config.pickEveryBlock) {
            cmd = new CActiveKube(pos[0], pos[2], pos[1])
        } else {
            cmd = new CSetBlocks([pos[0], pos[2], pos[1], old, b])
        }

        this.emit("command", cmd)
    }

    teleport(x: number, y: number, z: number) {
        this.emit("command", new CTeleport(Math.floor(x * 16), Math.floor(z * 16), Math.floor(y * 16), null))
        this.lastSave = [x, y, z]
        this.noa.ents.setPosition(this.noa.playerEntity, x, y, z)
    }
}
