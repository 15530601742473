import { PAGES } from "./App"
import { BaseDesign } from "./BaseDesign"
import { KubeMainBox } from "./components/KubeMainBox"
import kubeHead from "./img/head.jpg"
import kubeHeadBgRepeat from "./img/head_bg_repeat.jpg"
import { css } from "@emotion/react"
import { FC, PropsWithChildren, useEffect } from "react"
import { Link } from "react-router-dom"

const classes = {
    bg: css`
        background: url(${kubeHeadBgRepeat}) repeat-x;
    `,
    container: css`
        width: 900px;
        margin: 0 auto 0 auto;
    `,
    head: css`
        background: url(${kubeHead}) no-repeat top left;
        padding-bottom: 20px;
    `,
    linkHead: css`
        display: block;
        margin-top: 0;
        margin-left: 8px;
        outline: none;
        width: 883px;
        height: 120px;
    `,
    menu: css`
        display: flex;
        list-style: none;
        padding: 4px 0 0 7px;
        margin: 0;
        & li {
            margin-right: 66px;
            white-space: nowrap;
            & a {
                font-family: kubefont, monospace;
                font-size: 16px;
                color: #ddd;
                text-shadow: #000 1px 1px 0, #000 -1px -1px 0px;
                text-decoration: none;
                font-weight: bold;
                padding-top: 0;
                padding-left: 3px;
                & img {
                    vertical-align: -15%;
                }
                &:hover {
                    color: #ffbf1f;
                }
            }
        }
    `,
}

export const KubeContainer: FC<PropsWithChildren> = ({ children }) => {
    useEffect(() => {
        // @ts-ignore Hot reload
        module?.hot?.accept()
    }, [])

    return (
        <BaseDesign>
            <div css={classes.bg}>
                <div css={classes.container}>
                    <div css={classes.head}>
                        <Link css={classes.linkHead} to={"/"} />
                        <ul css={classes.menu}>
                            {PAGES.filter((el) => el.name || el.icon).map((el, i) => (
                                <li key={i}>
                                    <Link to={el.href}>
                                        <img src={el.icon} alt={el.name} /> {el.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <KubeMainBox>{children}</KubeMainBox>
                </div>
            </div>
        </BaseDesign>
    )
}
