import { IKubeClient } from "./IKubeClient"
import { _Answer } from "./enums/_Answer"
import { _Cmd } from "./enums/_Cmd"
import { Serializer, Unserializer } from "haxeformat"

const WEBSOCKET_URL = "ws://localhost:5000"

// Simple websocket client
// Send and receive commands without any authentication
export class WebsocketKubeClient extends IKubeClient {
    beforeOpenBuffer: _Cmd[] = []

    ws: WebSocket

    constructor(url?: string) {
        super()
        this.ws = new WebSocket(url || WEBSOCKET_URL)
        this.ws.onopen = this.onOpenWs.bind(this)
        this.ws.onclose = this.onCloseWs.bind(this)
        this.ws.onerror = this.onErrorWs.bind(this)
        this.ws.onmessage = this.onMessageWs.bind(this)
    }

    private isReady() {
        return this.ws.readyState === WebSocket.OPEN
    }

    // Websocket handlers
    private onOpenWs(ev: Event) {
        console.log("Connected to ", WEBSOCKET_URL)
        for (const cmd of this.beforeOpenBuffer) {
            this.sendCommand(cmd)
        }
    }

    private onErrorWs(ev: Event) {
        this.game.emit("clientError", "websocket error")
    }

    private onCloseWs(ev: CloseEvent) {
        this.ws.close()
        this.game.emit("clientError", "websocket closed")
    }

    private onMessageWs(ev: MessageEvent) {
        let data = ev.data
        let U = new Unserializer(data)
        let answer: _Answer = U.unserialize()

        console.log("receive", answer)
        this.game.emit("answer", answer)
    }

    // IKubeClient interface
    public sendCommand(cmd: _Cmd) {
        if (!this.isReady()) {
            this.beforeOpenBuffer.push(cmd)
            return
        }

        let S = new Serializer()
        S.useEnumIndex = true
        S.serialize(cmd)

        console.log("send", cmd)
        this.ws.send(S.toString())
    }
}
