import { Game } from "../game"
import { Config } from "../misc"

export abstract class GameComponent {
    game: Game
    config: Config

    protected constructor(game: Game) {
        this.game = game
        this.config = game.config

        if (game.noa.container._shell.onInit === null) {
            setTimeout(this.init.bind(this), 1) // Queue the function
        } else {
            game.noa.container.once("DOMReady", this.init.bind(this))
        }
        game.noa.on("tick", this.update.bind(this))
    }

    protected init() {}

    protected update(dt: number) {}

    // TODO
    public cleanup() {
        this.game.noa.off("tick", this.update.bind(this))
    }
}
