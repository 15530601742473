import { WEBSOCKET_BRIDGE } from "../defaultConfig"
import { IKubeClient } from "./IKubeClient"
import { _Answer } from "./enums"
import { _Cmd, CLoad, CWatch } from "./enums"
import { Serializer } from "haxeformat"
import { Unserializer } from "haxeformat"
import { CodecV2 as Codec } from "mt-codec"
import { ToraProtocol } from "tora-protocol"

// Mimic original Kube client using Tora protocol
// The websocket feature must be used with a raw socket bridge like websockify
export class ToraKubeClient extends IKubeClient {
    // id : "{mx}/{my}"
    loadedChunkTora: { [id: string]: ToraProtocol } = {}

    codec: Codec
    url: string
    useWebSocket: boolean
    webSocketBridge?: string
    sid: string

    constructor(
        k: string,
        sid: string,
        url: string,
        useWebSocket: boolean,
        webSocketBridge: string = WEBSOCKET_BRIDGE
    ) {
        super()
        this.codec = new Codec(k, Codec.KUBE_KEY_VERSION)
        this.useWebSocket = useWebSocket
        this.webSocketBridge = webSocketBridge
        this.sid = sid
        this.url = url
    }

    // Tora helpers
    private encodeCommand(cmd: _Cmd): string {
        let S = new Serializer()
        S.useEnumIndex = true
        S.serialize(cmd)
        let serialized = S.toString()
        return this.codec.encode(serialized)
    }

    private decodeAnswer(data: string): _Answer {
        let decoded = this.codec.decode(data)
        let U = new Unserializer(decoded)
        return U.unserialize()
    }

    private onToraData(data: string) {
        try {
            const answer = this.decodeAnswer(data)
            console.log((answer.constructor as typeof _Answer).tag, answer)
            if (answer instanceof _Answer) {
                this.game.emit("answer", answer, data.length)
            } else {
                console.error("Recerived data is not an answer", answer)
            }
        } catch (e) {
            this.game.emit("uiMessage", e?.message || e)
            console.error("Failed to parse server response :(", e)
        }
    }

    private onToraError(error: string) {
        this.game.emit("clientError", error)
    }

    private toraSend(
        cmd: _Cmd,
        protocol?: ToraProtocol,
        onData?: ToraProtocol["onData"],
        onError?: ToraProtocol["onError"]
    ): ToraProtocol {
        console.log((cmd.constructor as typeof _Cmd).tag, cmd)
        let data = this.encodeCommand(cmd)
        let tora = protocol || new ToraProtocol(this.url, this.useWebSocket, this.webSocketBridge)

        tora.addHeader("Cookie", `sid=${encodeURIComponent(this.sid)}`)
        tora.addParameters("__d", data)

        tora.onData = onData || this.onToraData.bind(this)
        tora.onError = onError || this.onToraError.bind(this)

        if (tora.sock === null) tora.connect()
        else tora.call(this.url)

        return tora
    }

    // IKubeClient
    public sendCommand(cmd: _Cmd): void {
        // Like Kube swf.
        if (cmd instanceof CLoad || cmd instanceof CWatch) {
            let protocol = this.loadedChunkTora[`${cmd.mx}/${cmd.my}`]
            protocol = this.toraSend(cmd, protocol)
            this.loadedChunkTora[`${cmd.mx}/${cmd.my}`] = protocol
        } else {
            const protocol = Object.values(this.loadedChunkTora).find((tora) => tora !== null)
            if (!protocol) console.warn("Creating a new socket to send the command. This should never happen.", cmd)
            this.toraSend(cmd, protocol)
        }
    }

    handleChunkRemoved(mx: number, my: number) {
        let protocol = this.loadedChunkTora[`${mx}/${my}`]
        protocol?.close()
        delete this.loadedChunkTora[`${mx}/${my}`]
    }
}
