import box1Bg from "../img/box1bg.gif"
import box1Bottom from "../img/box1bottom.gif"
import box1Top from "../img/box1top.gif"
import { EmotionProps } from "../types"
import { GenericBox } from "./GenericBox"
import { css } from "@emotion/react"
import { FC, PropsWithChildren } from "react"

export const KubeBox1: FC<PropsWithChildren & EmotionProps> = ({ children, className }) => {
    return (
        <GenericBox
            className={className}
            top={box1Top}
            topHeight={8}
            bottom={box1Bottom}
            bottomHeight={6}
            bg={box1Bg}
            maxWidth={242}>
            {children}
        </GenericBox>
    )
}
