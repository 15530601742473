import { GameContainer } from "../game/GameContainer"
import { Buffer } from "buffer"
import { IKubeClient, Kub3ditKubeClient, LocalGeneratorKubeClient, RawChunkKubeClient } from "kube-engine/src/network"
import React, { useEffect, useState } from "react"

export const WEBSOCKET_BRIDGE = "wss://kube-ws-bridge.thetoto.fr"

export const GameFrame = () => {
    const [client, setClient] = useState<IKubeClient>()

    useEffect(() => {
        return () => {
            // Hard reload
            console.log("Unload GamePlay...")
            location.reload()
        }
    }, [])

    const changeHandlerKub3dit: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const file: File = event.target.files[0]
        file.arrayBuffer().then((arrayBuffer) => {
            const fileChunk: Buffer = new Buffer(arrayBuffer)
            setClient(new Kub3ditKubeClient(fileChunk))
        })
    }
    const changeHandlerRawChunk: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const file: File = event.target.files[0]
        file.arrayBuffer().then((arrayBuffer) => {
            const fileChunk: Buffer = new Buffer(arrayBuffer)
            setClient(new RawChunkKubeClient(fileChunk))
        })
    }
    const loadLocalGenerator = () => {
        setClient(new LocalGeneratorKubeClient())
    }

    if (client) return <GameContainer client={client} />
    return (
        <ul>
            <li>
                Kub3dit file : <input type="file" name="file" onChange={changeHandlerKub3dit} />
            </li>
            <li>
                Raw chunk file : <input type="file" name="file" onChange={changeHandlerRawChunk} />
            </li>
            <li>
                <input type="button" value="Local generator" onClick={loadLocalGenerator} />
            </li>
        </ul>
    )
}

export const GameFrameFlashVars = () => {
    const [flashVars, setFlashVars] = useState<string | null>(null)
    const [wsBridge, setWsBridge] = useState<string>(WEBSOCKET_BRIDGE)

    useEffect(() => {
        window.onmessage = (e) => {
            if (!e.data["flashVars"]) return
            if (e.data["websocketBridge"]) setWsBridge(e.data["websocketBridge"])
            setFlashVars(e.data["flashVars"])
            console.log("FlashVars received :)", e.data)
            window.onmessage = undefined
        }
        window.parent.postMessage("ready", "*")

        return () => {
            // Hard reload
            console.log("Unload GameFrameFlashVars...")
            //location.reload()
        }
    }, [])

    return flashVars ? (
        <GameContainer websocketBridge={wsBridge} flashVars={flashVars} />
    ) : (
        <span>Wait for FlashVars...</span>
    )
}
