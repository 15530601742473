import { Texture } from "@babylonjs/core/Materials/Textures/texture"
import { Engine } from "noa-engine"

export enum Block {
    Empty = 0,
    Fixed,
    Water,
    SoilTree,
    SoilTree1,
    SoilTree2,
    AutumnTree,
    AutumnTree1,
    AutumnTree2,
    HighTree,
    HighTree1,
    HighTree2,
    HighTree3,
    Clouds,
    Clouds1,
    Clouds2,
    SphereTree,
    SphereTree1,
    LargeTree,
    LargeTree1,
    LargeTree2,
    SnowSapin,
    SnowSapin1,
    SnowSapin2,
    Jungle,
    Jungle1,
    Jungle2,
    Jungle3,
    Caverns,
    Caverns1,
    Caverns2,
    Field,
    Field1,
    Field2,
    Swamp,
    Swamp1,
    Swamp2,
    Swamp3,
    SoilPeaks,
    SoilPeaks1,
    PilarPlain,
    PilarPlain1,
    PilarPlain2,
    FlowerPlain,
    FlowerPlain1,
    FlowerPlain2,
    FlowerPlain3,
    Savana,
    Savana1,
    Savana2,
    Savana3,
    Desert,
    Desert1,
    Fruit,
    Lava,
    Dolpart,
    Dolmen,
    Flying,
    Flying1,
    Flying2,
    Koala,
    Invisible,
    Amethyste,
    Emeraude,
    Rubis,
    Saphir,
    Fog,
    Shade,
    Light,
    Gold,
    Message,
    Teleport,
    Jump,
    Chest,
    Multicol,
    MTwin,
    Crate,
    Noel,
}

// Try to use a texture atlas for blocks textures
const blocksParams: {
    [id: number]: { textures: string[]; alpha?: boolean; fluid?: boolean }
} = {
    [Block.Fixed]: { textures: ["01"] },
    [Block.Water]: { textures: ["02"], fluid: false }, // TODO
    [Block.SoilTree]: { textures: ["03_top", "03", "03"] },
    [Block.SoilTree1]: { textures: ["04_top_bottom", "04"] },
    [Block.SoilTree2]: { textures: ["05"], alpha: true },
    [Block.AutumnTree]: { textures: ["06_top", "06", "06"] },
    [Block.AutumnTree1]: { textures: ["07_top_bottom", "07"] },
    [Block.AutumnTree2]: {
        textures: ["08_top", "08", "08"],
        alpha: true,
    },
    [Block.HighTree]: { textures: ["09_top", "09", "09"] },
    [Block.HighTree1]: { textures: ["10_top_bottom", "10"] },
    [Block.HighTree2]: { textures: ["11_top", "11", "11"] },
    [Block.HighTree3]: { textures: ["12"], alpha: true },
    [Block.Clouds]: { textures: ["13_top", "13", "13"] },
    [Block.Clouds1]: { textures: ["14"] },
    [Block.Clouds2]: { textures: ["15"] },
    [Block.SphereTree]: { textures: ["16_top_bottom", "16"] },
    [Block.SphereTree1]: {
        textures: ["17_top", "17", "17"],
        alpha: true,
    },
    [Block.LargeTree]: { textures: ["18_top", "18", "18"] },
    [Block.LargeTree1]: { textures: ["19_top_bottom", "19"] },
    [Block.LargeTree2]: { textures: ["20"], alpha: true },
    [Block.SnowSapin]: { textures: ["21_top", "21", "21"] },
    [Block.SnowSapin1]: { textures: ["22_top_bottom", "22"] },
    [Block.SnowSapin2]: {
        textures: ["23_top", "23", "23"],
        alpha: true,
    },
    [Block.Jungle]: { textures: ["24_top", "24", "24"] },
    [Block.Jungle1]: {
        textures: ["25_top", "25", "25"],
        alpha: true,
    },
    [Block.Jungle2]: { textures: ["26_top", "26", "26"] },
    [Block.Jungle3]: { textures: ["27"], alpha: true },
    [Block.Caverns]: { textures: ["28_top", "28", "28"] },
    [Block.Caverns1]: { textures: ["29"] },
    [Block.Caverns2]: { textures: ["30"] },
    [Block.Field]: { textures: ["31_top", "31", "31"] },
    [Block.Field1]: {
        textures: ["32_top", "32", "32"],
        alpha: true,
    },
    [Block.Field2]: {
        textures: ["33_top", "33", "33"],
        alpha: true,
    },
    [Block.Swamp]: { textures: ["34_top", "34", "34"] },
    [Block.Swamp1]: { textures: ["35_top_bottom", "35"] },
    [Block.Swamp2]: { textures: ["36"], alpha: true },
    [Block.Swamp3]: { textures: ["37_top", "37", "37"] },
    [Block.SoilPeaks]: { textures: ["38"] },
    [Block.SoilPeaks1]: { textures: ["39"] },
    [Block.PilarPlain]: { textures: ["40_top", "40", "40"] },
    [Block.PilarPlain1]: { textures: ["41"] },
    [Block.PilarPlain2]: { textures: ["42"] },
    [Block.FlowerPlain]: { textures: ["43_top", "43", "43"] },
    [Block.FlowerPlain1]: { textures: ["44_top", "44", "44"] },
    [Block.FlowerPlain2]: { textures: ["45_top", "45", "45"] },
    [Block.FlowerPlain3]: { textures: ["46"] },
    [Block.Savana]: { textures: ["47_top", "47", "47"] },
    [Block.Savana1]: { textures: ["48_top", "48", "48"] },
    [Block.Savana2]: { textures: ["49"] },
    [Block.Savana3]: { textures: ["50"] },
    [Block.Desert]: { textures: ["51"] },
    [Block.Desert1]: { textures: ["52_top_bottom", "52"] },
    [Block.Fruit]: { textures: ["53_top", "53_bottom", "53"] },
    [Block.Lava]: { textures: ["54"] },
    [Block.Dolpart]: { textures: ["55"] },
    [Block.Dolmen]: { textures: ["56"] },
    [Block.Flying]: { textures: ["57"] },
    [Block.Flying1]: { textures: ["58_top_bottom", "58"] },
    [Block.Flying2]: { textures: ["59"] },
    [Block.Koala]: { textures: ["60"] },
    [Block.Invisible]: { textures: ["61"] },
    [Block.Amethyste]: { textures: ["62"] },
    [Block.Emeraude]: { textures: ["63"] },
    [Block.Rubis]: { textures: ["64"] },
    [Block.Saphir]: { textures: ["65"] },
    [Block.Fog]: { textures: ["66"] },
    [Block.Shade]: { textures: ["67"] },
    [Block.Light]: { textures: ["68"] },
    [Block.Gold]: { textures: ["69"] },
    [Block.Message]: { textures: ["70_top_bottom", "70"] },
    [Block.Teleport]: { textures: ["71"], alpha: true },
    [Block.Jump]: { textures: ["72_top", "72_bottom", "72"] },
    [Block.Chest]: { textures: ["73_top_bottom", "73"] },
    [Block.Multicol]: { textures: ["74_top", "74_bottom", "74"] },
    [Block.MTwin]: { textures: ["75_top", "75_bottom", "75"] },
    [Block.Crate]: { textures: ["76_top", "76_bottom", "76"] },
    [Block.Noel]: { textures: ["77_top", "77_bottom", "77"] },
}

export const registerBlocks = (noa: Engine) => {
    const materials = require("../materials/*.png")
    for (const id in blocksParams) {
        const params = blocksParams[id]
        for (const texture of params.textures) {
            noa.registry.registerMaterial(texture, null, materials[texture], params.alpha)
        }
        noa.registry.registerBlock(id, {
            material: params.textures.length == 1 ? params.textures[0] : params.textures,
            opaque: !params.alpha,
            solid: !params.fluid,
            fluid: params.fluid,
            fluidDensity: 100,
            viscosity: 100,
        })
    }
}

export const isTouchable = (b: Block) => {
    return [Block.Dolmen, Block.Chest, Block.Message].includes(b)
}

export const BlocksColors: {
    [id: number]: number[] | null // RGB
} = {
    [Block.Empty]: null,
    [Block.Fixed]: [145, 134, 135],
    [Block.Water]: [57, 168, 198],
    [Block.SoilTree]: [181, 128, 58],
    [Block.SoilTree1]: [110, 75, 45],
    [Block.SoilTree2]: [138, 118, 22],
    [Block.AutumnTree]: [204, 121, 47],
    [Block.AutumnTree1]: [204, 169, 132],
    [Block.AutumnTree2]: [151, 98, 41],
    [Block.HighTree]: [61, 82, 30],
    [Block.HighTree1]: [110, 75, 45],
    [Block.HighTree2]: [80, 140, 59],
    [Block.HighTree3]: [59, 75, 0],
    [Block.Clouds]: [65, 140, 152],
    [Block.Clouds1]: [232, 238, 237],
    [Block.Clouds2]: [199, 210, 212],
    [Block.SphereTree]: [196, 169, 143],
    [Block.SphereTree1]: [153, 66, 122],
    [Block.LargeTree]: [207, 139, 92],
    [Block.LargeTree1]: [172, 86, 43],
    [Block.LargeTree2]: [185, 66, 0],
    [Block.SnowSapin]: [238, 238, 235],
    [Block.SnowSapin1]: [110, 75, 45],
    [Block.SnowSapin2]: [238, 238, 236],
    [Block.Jungle]: [105, 142, 35],
    [Block.Jungle1]: [24, 44, 0],
    [Block.Jungle2]: [80, 140, 59],
    [Block.Jungle3]: [109, 120, 49],
    [Block.Caverns]: [86, 79, 75],
    [Block.Caverns1]: [101, 70, 35],
    [Block.Caverns2]: [127, 94, 61],
    [Block.Field]: [78, 100, 23],
    [Block.Field1]: [177, 178, 104],
    [Block.Field2]: [154, 141, 25],
    [Block.Swamp]: [71, 100, 44],
    [Block.Swamp1]: [196, 161, 85],
    [Block.Swamp2]: [123, 107, 75],
    [Block.Swamp3]: [114, 128, 74],
    [Block.SoilPeaks]: [89, 68, 57],
    [Block.SoilPeaks1]: [43, 31, 25],
    [Block.PilarPlain]: [166, 148, 142],
    [Block.PilarPlain1]: [166, 154, 151],
    [Block.PilarPlain2]: [123, 110, 102],
    [Block.FlowerPlain]: [72, 112, 56],
    [Block.FlowerPlain1]: [173, 171, 90],
    [Block.FlowerPlain2]: [155, 105, 102],
    [Block.FlowerPlain3]: [140, 143, 147],
    [Block.Savana]: [175, 145, 65],
    [Block.Savana1]: [217, 185, 114],
    [Block.Savana2]: [105, 102, 102],
    [Block.Savana3]: [113, 89, 76],
    [Block.Desert]: [199, 144, 97],
    [Block.Desert1]: [86, 115, 45],
    [Block.Fruit]: [157, 113, 1],
    [Block.Lava]: [200, 53, 0],
    [Block.Dolpart]: [41, 35, 32],
    [Block.Dolmen]: [73, 64, 57],
    [Block.Flying]: [110, 103, 142],
    [Block.Flying1]: [82, 79, 90],
    [Block.Flying2]: [208, 203, 183],
    [Block.Koala]: [176, 163, 147],
    [Block.Invisible]: null,
    [Block.Amethyste]: [193, 72, 242],
    [Block.Emeraude]: [11, 210, 7],
    [Block.Rubis]: [210, 6, 73],
    [Block.Saphir]: [6, 102, 210],
    [Block.Fog]: [102, 108, 133],
    [Block.Shade]: [50, 55, 76],
    [Block.Light]: [239, 217, 125],
    [Block.Gold]: [252, 220, 45],
    [Block.Message]: [108, 79, 43],
    [Block.Teleport]: [234, 229, 229],
    [Block.Jump]: [190, 148, 158],
    [Block.Chest]: [117, 58, 37],
    [Block.Multicol]: [124, 123, 100],
    [Block.MTwin]: [90, 29, 44],
    [Block.Crate]: [216, 136, 58],
    [Block.Noel]: [176, 185, 243],
}
