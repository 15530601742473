import kubeMiddle from "../img/game/kube_middle.jpg"
import { Map } from "./Map"
import { css } from "@emotion/react"
import { MapGame, Game } from "kube-engine/src/game"
import { IKubeClient, LocalGeneratorKubeClient } from "kube-engine/src/network"
import { KubeDataFlashVars } from "kube-engine/src/types"
import { useEffect, useRef, useState } from "react"

const classes = {
    game: css`
        flex-grow: 1;
        background: url(${kubeMiddle});
        background-size: 100% 100%;
    `,
    container: css`
        height: 100vh;
        display: flex;
    `,
}

export const MapContainer = ({
    flashVars,
    client,
    dataOverride,
    noaOptionsOverride,
    websocketBridge,
}: {
    flashVars?: string
    client?: IKubeClient
    dataOverride?: Partial<KubeDataFlashVars>
    noaOptionsOverride?: any
    websocketBridge?: string
}) => {
    const gameRef = useRef<HTMLDivElement>()
    const containerRef = useRef<HTMLDivElement>()

    const [game, setGame] = useState<MapGame>()
    const [zoom, setZoom] = useState(3)
    const [uiInfo, setUiInfo] = useState<string>()

    useEffect(() => {
        if (!gameRef.current || game) return
        console.log(gameRef?.current, flashVars, client, dataOverride, noaOptionsOverride, websocketBridge)
        let initGame
        if (flashVars !== undefined) {
            initGame = MapGame.fromFlashVars(
                gameRef.current,
                flashVars,
                websocketBridge,
                dataOverride,
                noaOptionsOverride
            )
        } else if (client !== undefined) {
            initGame = MapGame.fromExistingClient(gameRef.current, client, dataOverride, noaOptionsOverride)
        } else {
            initGame = MapGame.fromExistingClient(
                gameRef.current,
                new LocalGeneratorKubeClient(),
                dataOverride,
                noaOptionsOverride
            )
        }

        setGame(initGame)

        initGame.on("uiInfo", setUiInfo)

        // @ts-ignore
        window.game = initGame
    }, [gameRef?.current, flashVars, client, dataOverride, noaOptionsOverride, websocketBridge])

    return (
        <div css={classes.container} ref={containerRef}>
            <div style={{ display: "flex", position: "absolute" }}>
                <button
                    onClick={() =>
                        document.fullscreenElement
                            ? document.exitFullscreen()
                            : containerRef?.current.requestFullscreen()
                    }>
                    Toggle screen
                </button>
                <button disabled={zoom <= 1} onClick={() => setZoom((old) => old - 1)}>
                    Zoom -
                </button>
                <button disabled={zoom >= 3} onClick={() => setZoom((old) => old + 1)}>
                    Zoom +
                </button>
                <div>info: {uiInfo}</div>
            </div>
            <div style={{ display: "none" }} id="game" ref={gameRef} />
            <div id="map" css={classes.game}>
                {game && <Map zoom={zoom} game={game} />}
            </div>
        </div>
    )
}
