import kubeBar from "../img/game/kube_bar.jpg"
import { css } from "@emotion/react"
import { Game } from "kube-engine/src/game"
import { useCallback, useEffect, useState } from "react"

const classes = {
    power: css`
        position: absolute;
        top: 21px;
        left: 19px;
        width: 154px;
        & span {
            display: block;
            height: 22px;
            background: url(${kubeBar});
            box-shadow: inset -1px 0 0 rgb(0, 0, 0, 13%);
        }
    `,
    powerWater: css`
        filter: saturate(1000%); // Not perfect
    `,
}

export const PowerBar = ({ game }: { game: Game }) => {
    const [swim, setSwim] = useState(game.player.swim)
    const [power, setPower] = useState(game.player.power)

    const handleUiPower = useCallback((power: number, swim: number | null) => {
        setPower(power)
        setSwim(swim)
    }, [])

    useEffect(() => {
        game.on("uiPower", handleUiPower)
        return () => {
            game.off("uiPower", handleUiPower)
        }
    }, [game])

    return (
        <div css={classes.power}>
            <span
                css={swim !== null && classes.powerWater}
                style={{ width: `${Math.floor((swim ?? power) / 100)}%` }}
            />
        </div>
    )
}
