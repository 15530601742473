import { HaxeEnum } from "haxeformat"

export class _GO extends HaxeEnum {
    static readonly enum = "_GO"

    static getEnumConstructs() {
        return [GOWater, GOLava] as typeof HaxeEnum[]
    }
}

export class GOWater extends _GO {
    static readonly tag = "GOWater"

    getParams() {
        return []
    }
}

export class GOLava extends _GO {
    static readonly tag = "GOLava"

    getParams() {
        return []
    }
}
