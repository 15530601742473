import { IKubeClient } from "./IKubeClient"
import { AMap } from "./enums/_Answer"
import { _Cmd, CLoad, CWatch } from "./enums/_Cmd"
import { Buffer } from "buffer"

export class RawChunkKubeClient extends IKubeClient {
    fileChunk: Buffer

    constructor(fileChunk: Buffer) {
        super(true)
        this.fileChunk = fileChunk
        if (fileChunk.length !== 256 * 256 * 32) throw new Error("Invalid file size (must be 256*256*32 bytes)")
    }

    // IKubeClient interface
    public sendCommand(cmd: _Cmd) {
        if (cmd instanceof CWatch || cmd instanceof CLoad) {
            this.game.emit("answer", new AMap(cmd.mx, cmd.my, this.fileChunk, null, new Array(64).fill(null)))
        }
        this.localHelperSendCommand(cmd)
    }
}
