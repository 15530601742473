import box4Bg from "../img/box4bg.gif"
import box4Bottom from "../img/box4bottom.gif"
import box4Top from "../img/box4top.gif"
import { EmotionProps } from "../types"
import { GenericBox } from "./GenericBox"
import { FC, PropsWithChildren } from "react"

export const KubeBox4: FC<PropsWithChildren & EmotionProps> = ({ children, className }) => {
    return (
        <GenericBox
            className={className}
            top={box4Top}
            topHeight={8}
            bottom={box4Bottom}
            bottomHeight={9}
            bg={box4Bg}
            maxWidth={848}
            separator>
            {children}
        </GenericBox>
    )
}
