import { KubeContainer } from "./KubeContainer"
import forumIcon from "./img/icons/forum.gif"
import helpIcon from "./img/icons/help.gif"
import homeIcon from "./img/icons/home.gif"
import mapIcon from "./img/icons/map.gif"
import playIcon from "./img/icons/play.gif"
import shopIcon from "./img/icons/shop.gif"
import { ForumPage } from "./pages/ForumPage"
import { GameFrame, GameFrameFlashVars } from "./pages/GameFrame"
import { HelpPage } from "./pages/HelpPage"
import { HomePage } from "./pages/HomePage"
import { MainPage } from "./pages/MainPage"
import { MapFrame, MapFrameFlashVars } from "./pages/MapFrame"
import { MapPage } from "./pages/MapPage"
import { ShopPage } from "./pages/ShopPage"
import { ReactNode } from "react"
import { Route, Routes, HashRouter } from "react-router-dom"

export interface PageLink {
    name?: string
    icon?: string
    href: string
    wrapInKubeContainer?: boolean
    component?: ReactNode
}

export const PAGES: PageLink[] = [
    { name: "Jouer", href: "/", icon: playIcon, component: <KubeContainer children={<MainPage />} /> },
    { name: "Carte", href: "/map", icon: mapIcon, component: <KubeContainer children={<MapPage />} /> },
    { name: "Boutique", href: "/shop", icon: shopIcon, component: <KubeContainer children={<ShopPage />} /> },
    { name: "Ma page", href: "/home", icon: homeIcon, component: <KubeContainer children={<HomePage />} /> },
    { name: "Aide", href: "/help", icon: helpIcon, component: <KubeContainer children={<HelpPage />} /> },
    { name: "Forum", href: "/forum", icon: forumIcon, component: <KubeContainer children={<ForumPage />} /> },

    { href: "/zone/choose", component: <KubeContainer children={<p>TODO Zone choose</p>} /> },
    { href: "/zone/edit/:zoneX.:zoneY", component: <KubeContainer children={<p>TODO Zone edit</p>} /> },
    { href: "/zone/remove/:zoneX.:zoneY", component: <KubeContainer children={<p>TODO Zone remove</p>} /> },
    { href: "/zone/infos/:zoneX.:zoneY", component: <KubeContainer children={<p>TODO Zone infos</p>} /> },
    { href: "/user/:userId", component: <KubeContainer children={<p>TODO See User profile</p>} /> },
    { href: "/photo/:photoId", component: <KubeContainer children={<p>TODO See Photo</p>} /> },

    { href: "/game/play", component: <GameFrame /> },
    { href: "/game/flashvars", component: <GameFrameFlashVars /> },
    { href: "/game/map", component: <MapFrame /> },
    { href: "/game/map/flashvars", component: <MapFrameFlashVars /> },
]

export const App = () => {
    return (
        <HashRouter>
            <Routes>
                {PAGES.map((page) => (
                    <Route key={page.href} path={page.href} element={page.component} />
                ))}
            </Routes>
        </HashRouter>
    )
}
