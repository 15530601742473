import box3Bg from "../img/box3bg.jpg"
import { EmotionProps } from "../types"
import { css } from "@emotion/react"
import { FC, PropsWithChildren } from "react"

const classes = {
    bg: css`
        background: #44526f url(${box3Bg}) no-repeat;
        margin: 10px 0;
        padding: 5px 10px;
        border: 1px solid #0b376e;
        color: #fff;
    `,
}

export const KubeBox3: FC<PropsWithChildren & EmotionProps> = ({ children, className }) => {
    return (
        <div className={className} css={classes.bg}>
            {children}
        </div>
    )
}
