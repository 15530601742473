import "./kube-iso-spritesheet.css"
import { css } from "@emotion/react"
import { Game } from "kube-engine/src/game"
import { useCallback, useEffect, useState } from "react"

export const classes = {
    inventory: css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 7px;
        margin-top: 69px;
        margin-left: 16px;
        margin-right: 3px;

        & > div {
            position: relative;

            & > img {
                width: 100%;
                height: 100%;
            }

            & > span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-shadow: 0 0 3px white, 0 0 3px white, 0 0 3px white, 0 0 3px white;
                font-size: small;
            }
        }
    `,
    invSelected: css`
        filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 1px white)
            drop-shadow(0px 0px 1px white);
    `,
    invMax: css`
        color: red;
    `,
}

export const Inventory = ({ game, selected, page }: { game: Game; selected: number | null; page: number }) => {
    const [inv, setInv] = useState<(number | null)[]>([])

    useEffect(() => {
        setInv(game.player.inventory)
        game.on("uiInventory", setInv)
        return () => {
            game.off("uiInventory", setInv)
        }
    }, [game])

    const onSelectKube = (idx: number) => {
        game.emit("selectBlock", idx)
    }

    let i = 0
    return (
        <div css={classes.inventory}>
            {inv.map((count, idx) => {
                if (count === null) return
                i++
                if (i <= page * 16 || i > (page + 1) * 16) return
                const blockId = String(idx).padStart(2, "0")
                return (
                    <div
                        key={idx}
                        className={`kube-iso kube-iso-${blockId}`}
                        css={[
                            selected === idx && classes.invSelected,
                            count === game.player.inventoryMax && classes.invMax,
                        ]}
                        onClick={() => onSelectKube(idx)}>
                        <span>{count}</span>
                    </div>
                )
            })}
        </div>
    )
}
