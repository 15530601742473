import { KubeBox1 } from "../components/KubeBox1"
import { KubeBox2 } from "../components/KubeBox2"
import { KubeBox3 } from "../components/KubeBox3"
import { KubeBox4 } from "../components/KubeBox4"
import { KubeButton } from "../components/KubeButton"
import { KubeMainBox } from "../components/KubeMainBox"

export const ForumPage = () => {
    return (
        <>
            <div>Hello World</div>
            <KubeMainBox>
                <KubeButton>a</KubeButton>
                <div>b</div>
            </KubeMainBox>
            <KubeBox1>
                <div>a</div>
                <div>b</div>
            </KubeBox1>
            <KubeBox2>
                <div>a</div>
                <div>b</div>
            </KubeBox2>
            <KubeBox3>
                <div>a</div>
                <div>b</div>
            </KubeBox3>
            <KubeBox4>
                <div>a</div>
                <div>b</div>
            </KubeBox4>
        </>
    )
}
