import { CSavePhoto } from "../network/enums/_Cmd"
import { GameComponent } from "../types/GameComponent"
import { Game } from "./Game"
import { Engine } from "noa-engine"

declare module "./Game" {
    interface GameEvents {
        takePhoto: () => void

        uiPhotoSaved: (pngUrl: string, zx: number, zy: number) => void
    }
}

export class Photo extends GameComponent {
    world: Engine["world"]
    camera: Engine["camera"]

    playerPosition: number[]

    constructor(game: Game) {
        super(game)

        this.world = game.noa.world
        this.camera = game.noa.camera
        this.playerPosition = game.noa.ents.getPosition(game.noa.playerEntity)

        this.game.on("takePhoto", this.takePhoto.bind(this))
    }

    takePhoto() {
        this.playerPosition = this.game.noa.ents.getPosition(this.game.noa.playerEntity)

        let canvas: HTMLCanvasElement = this.game.noa.container.canvas
        if (!canvas) return
        // TODO: Make something with a raycast to determine the actual photo zone.
        const chunkPlayerPosition = this.world._coordsToChunkIndexes(this.playerPosition[0], 1, this.playerPosition[2])

        const pngUrl = canvas.toDataURL("png")
        this.game.emit("uiPhotoSaved", pngUrl, chunkPlayerPosition[0], chunkPlayerPosition[2])

        if (this.config.sendSavePhotoCommand) {
            this.sendSavePhoto(canvas, chunkPlayerPosition[0], chunkPlayerPosition[2])
        }
    }

    async sendSavePhoto(canvas: HTMLCanvasElement, zx: number, zy: number) {
        const big = await createImageBitmap(canvas, {
            resizeWidth: 400,
            resizeQuality: "low",
        })
        const small = await createImageBitmap(canvas, {
            resizeWidth: 100,
            resizeQuality: "low",
        })

        this.game.emit("command", new CSavePhoto(zx, zy, this.encodeToDataUrl(big), this.encodeToDataUrl(small), false))
    }

    encodeToDataUrl(img: ImageBitmap) {
        const c = document.createElement("canvas")
        c.height = img.height
        c.width = img.width
        var ctx = c.getContext("2d")

        ctx.drawImage(img, 0, 0, c.width, c.height)

        c.remove()
        return c.toDataURL()
    }
}
