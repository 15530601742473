import { css } from "@emotion/react"

const classes = {
    iframe: css`
        display: flex;
        border: none !important;
        margin: auto;
    `,
}

export const MapPage = () => {
    return (
        <>
            <iframe
                css={classes.iframe}
                height={320}
                width={812}
                src={location.origin + location.pathname + "/#/game/map"}
            />
        </>
    )
}
