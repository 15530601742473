{
    "name": "noa-engine",
    "version": "0.33.0",
    "description": "Experimental voxel game engine",
    "main": "src/index.js",
    "typings": "dist/src/index.d.ts",
    "files": [
        "/src",
        "/dist"
    ],
    "scripts": {
        "build": "npm run types; npm run docs",
        "types": "tsc",
        "docs": "typedoc"
    },
    "author": "Andy Hall (https://fenomas.com)",
    "license": "MIT",
    "repository": {
        "type": "git",
        "url": "https://github.com/fenomas/noa.git"
    },
    "bugs": {
        "url": "https://github.com/fenomas/noa/issues"
    },
    "dependencies": {
        "aabb-3d": "fenomas/aabb-3d",
        "box-intersect": "fenomas/box-intersect",
        "ent-comp": "^0.10.1",
        "events": "^3.3.0",
        "fast-voxel-raycast": "^0.1.1",
        "game-inputs": "link:../game-inputs",
        "gl-vec3": "^1.1.3",
        "micro-game-shell": "^0.5.0",
        "ndarray": "^1.0.19",
        "voxel-aabb-sweep": "^0.5.0",
        "voxel-physics-engine": "link:../voxel-physics-engine"
    },
    "peerDependencies": {
        "@babylonjs/core": "^5.2.0"
    },
    "devDependencies": {
        "eslint": "^8.3.0",
        "js-beautify": "^1.14.0",
        "typedoc": "^0.22.9",
        "typescript": "^4.4.4"
    },
    "keywords": [
        "voxel",
        "voxels",
        "game",
        "engine",
        "game-engine"
    ]
}
