import kubeBgRepeat from "./img/bg_repeat.gif"
import eternalTwinLogo from "./img/eternaltwin.png"
import muxxuMiniLogo from "./img/muxxu_minilogo.png"
import muxxuBarBg from "./img/muxxu_top_bg.gif"
import { css } from "@emotion/react"
import { FC, PropsWithChildren } from "react"

const classes = {
    bg: css`
        min-height: 100vh;
        width: 100%;
        height: 100%;
        background: url(${kubeBgRepeat});
    `,
    muxxuBar: css`
        background: url(${muxxuBarBg});
        height: 34px;
    `,
    muxxuLogo: css`
        padding: 10px;
    `,
    eternaltwinLogo: css`
        height: 34px;
        filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 6px white);
    `,
}

export const BaseDesign: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div css={classes.bg}>
            <div css={classes.muxxuBar}>
                <img src={muxxuMiniLogo} css={classes.muxxuLogo} alt={"Muxxu logo"} />
                <img src={eternalTwinLogo} css={classes.eternaltwinLogo} alt={"EternalTwin Logo"} />
            </div>
            {children}
        </div>
    )
}
