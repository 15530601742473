const _temp0 = require("./10.png");
const _temp1 = require("./11.png");
const _temp2 = require("./12.png");
const _temp3 = require("./13.png");
const _temp4 = require("./14.png");
const _temp5 = require("./15.png");
const _temp6 = require("./16.png");
const _temp7 = require("./17.png");
const _temp8 = require("./18.png");
const _temp9 = require("./19.png");
const _temp10 = require("./20.png");
const _temp11 = require("./21.png");
const _temp12 = require("./22.png");
const _temp13 = require("./23.png");
const _temp14 = require("./24.png");
const _temp15 = require("./25.png");
const _temp16 = require("./26.png");
const _temp17 = require("./27.png");
const _temp18 = require("./28.png");
const _temp19 = require("./29.png");
const _temp20 = require("./30.png");
const _temp21 = require("./31.png");
const _temp22 = require("./32.png");
const _temp23 = require("./33.png");
const _temp24 = require("./34.png");
const _temp25 = require("./35.png");
const _temp26 = require("./36.png");
const _temp27 = require("./37.png");
const _temp28 = require("./38.png");
const _temp29 = require("./39.png");
const _temp30 = require("./40.png");
const _temp31 = require("./41.png");
const _temp32 = require("./42.png");
const _temp33 = require("./43.png");
const _temp34 = require("./44.png");
const _temp35 = require("./45.png");
const _temp36 = require("./46.png");
const _temp37 = require("./47.png");
const _temp38 = require("./48.png");
const _temp39 = require("./49.png");
const _temp40 = require("./50.png");
const _temp41 = require("./51.png");
const _temp42 = require("./52.png");
const _temp43 = require("./53.png");
const _temp44 = require("./54.png");
const _temp45 = require("./55.png");
const _temp46 = require("./56.png");
const _temp47 = require("./57.png");
const _temp48 = require("./58.png");
const _temp49 = require("./59.png");
const _temp50 = require("./60.png");
const _temp51 = require("./61.png");
const _temp52 = require("./62.png");
const _temp53 = require("./63.png");
const _temp54 = require("./64.png");
const _temp55 = require("./65.png");
const _temp56 = require("./66.png");
const _temp57 = require("./67.png");
const _temp58 = require("./68.png");
const _temp59 = require("./69.png");
const _temp60 = require("./70.png");
const _temp61 = require("./71.png");
const _temp62 = require("./72.png");
const _temp63 = require("./73.png");
const _temp64 = require("./74.png");
const _temp65 = require("./75.png");
const _temp66 = require("./76.png");
const _temp67 = require("./77.png");
const _temp68 = require("./01.png");
const _temp69 = require("./02.png");
const _temp70 = require("./03.png");
const _temp71 = require("./03_top.png");
const _temp72 = require("./04.png");
const _temp73 = require("./04_top_bottom.png");
const _temp74 = require("./05.png");
const _temp75 = require("./06.png");
const _temp76 = require("./06_top.png");
const _temp77 = require("./07.png");
const _temp78 = require("./07_top_bottom.png");
const _temp79 = require("./08.png");
const _temp80 = require("./08_top.png");
const _temp81 = require("./09.png");
const _temp82 = require("./09_top.png");
const _temp83 = require("./10_top_bottom.png");
const _temp84 = require("./11_top.png");
const _temp85 = require("./13_top.png");
const _temp86 = require("./16_top_bottom.png");
const _temp87 = require("./17_top.png");
const _temp88 = require("./18_top.png");
const _temp89 = require("./19_top_bottom.png");
const _temp90 = require("./21_top.png");
const _temp91 = require("./22_top_bottom.png");
const _temp92 = require("./23_top.png");
const _temp93 = require("./24_top.png");
const _temp94 = require("./25_top.png");
const _temp95 = require("./26_top.png");
const _temp96 = require("./28_top.png");
const _temp97 = require("./31_top.png");
const _temp98 = require("./32_top.png");
const _temp99 = require("./33_top.png");
const _temp100 = require("./34_top.png");
const _temp101 = require("./35_top_bottom.png");
const _temp102 = require("./37_top.png");
const _temp103 = require("./40_top.png");
const _temp104 = require("./43_top.png");
const _temp105 = require("./44_top.png");
const _temp106 = require("./45_top.png");
const _temp107 = require("./47_top.png");
const _temp108 = require("./48_top.png");
const _temp109 = require("./52_top_bottom.png");
const _temp110 = require("./53_bottom.png");
const _temp111 = require("./53_top.png");
const _temp112 = require("./58_top_bottom.png");
const _temp113 = require("./70_top_bottom.png");
const _temp114 = require("./72_bottom.png");
const _temp115 = require("./72_top.png");
const _temp116 = require("./73_top_bottom.png");
const _temp117 = require("./74_bottom.png");
const _temp118 = require("./74_top.png");
const _temp119 = require("./75_bottom.png");
const _temp120 = require("./75_top.png");
const _temp121 = require("./76_bottom.png");
const _temp122 = require("./76_top.png");
const _temp123 = require("./77_bottom.png");
const _temp124 = require("./77_top.png");
module.exports = {
  "10": _temp0,
  "11": _temp1,
  "12": _temp2,
  "13": _temp3,
  "14": _temp4,
  "15": _temp5,
  "16": _temp6,
  "17": _temp7,
  "18": _temp8,
  "19": _temp9,
  "20": _temp10,
  "21": _temp11,
  "22": _temp12,
  "23": _temp13,
  "24": _temp14,
  "25": _temp15,
  "26": _temp16,
  "27": _temp17,
  "28": _temp18,
  "29": _temp19,
  "30": _temp20,
  "31": _temp21,
  "32": _temp22,
  "33": _temp23,
  "34": _temp24,
  "35": _temp25,
  "36": _temp26,
  "37": _temp27,
  "38": _temp28,
  "39": _temp29,
  "40": _temp30,
  "41": _temp31,
  "42": _temp32,
  "43": _temp33,
  "44": _temp34,
  "45": _temp35,
  "46": _temp36,
  "47": _temp37,
  "48": _temp38,
  "49": _temp39,
  "50": _temp40,
  "51": _temp41,
  "52": _temp42,
  "53": _temp43,
  "54": _temp44,
  "55": _temp45,
  "56": _temp46,
  "57": _temp47,
  "58": _temp48,
  "59": _temp49,
  "60": _temp50,
  "61": _temp51,
  "62": _temp52,
  "63": _temp53,
  "64": _temp54,
  "65": _temp55,
  "66": _temp56,
  "67": _temp57,
  "68": _temp58,
  "69": _temp59,
  "70": _temp60,
  "71": _temp61,
  "72": _temp62,
  "73": _temp63,
  "74": _temp64,
  "75": _temp65,
  "76": _temp66,
  "77": _temp67,
  "01": _temp68,
  "02": _temp69,
  "03": _temp70,
  "03_top": _temp71,
  "04": _temp72,
  "04_top_bottom": _temp73,
  "05": _temp74,
  "06": _temp75,
  "06_top": _temp76,
  "07": _temp77,
  "07_top_bottom": _temp78,
  "08": _temp79,
  "08_top": _temp80,
  "09": _temp81,
  "09_top": _temp82,
  "10_top_bottom": _temp83,
  "11_top": _temp84,
  "13_top": _temp85,
  "16_top_bottom": _temp86,
  "17_top": _temp87,
  "18_top": _temp88,
  "19_top_bottom": _temp89,
  "21_top": _temp90,
  "22_top_bottom": _temp91,
  "23_top": _temp92,
  "24_top": _temp93,
  "25_top": _temp94,
  "26_top": _temp95,
  "28_top": _temp96,
  "31_top": _temp97,
  "32_top": _temp98,
  "33_top": _temp99,
  "34_top": _temp100,
  "35_top_bottom": _temp101,
  "37_top": _temp102,
  "40_top": _temp103,
  "43_top": _temp104,
  "44_top": _temp105,
  "45_top": _temp106,
  "47_top": _temp107,
  "48_top": _temp108,
  "52_top_bottom": _temp109,
  "53_bottom": _temp110,
  "53_top": _temp111,
  "58_top_bottom": _temp112,
  "70_top_bottom": _temp113,
  "72_bottom": _temp114,
  "72_top": _temp115,
  "73_top_bottom": _temp116,
  "74_bottom": _temp117,
  "74_top": _temp118,
  "75_bottom": _temp119,
  "75_top": _temp120,
  "76_bottom": _temp121,
  "76_top": _temp122,
  "77_bottom": _temp123,
  "77_top": _temp124
}