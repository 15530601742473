import { CHUNK_SIZE } from "../defaultConfig"
import { IKubeClient, parseFlashVars, ToraKubeClient } from "../network"
import { KubeDataFlashVars, MapFlashVars } from "../types/FlashVars"
import { Game, KUBE_KEY_PREFIX } from "./Game"
import { ChunkUserData } from "./chunkHelper"

declare module "./Game" {
    interface GameEvents {
        moveMouse: (dx: number, dy: number) => void
        moveMap: (dx: number, dy: number) => void
        setMapZoom: (zoomLevel: number, size: number) => void

        uiInfo: (message: string) => void
    }
}

export class MapGame extends Game {
    static fromFlashVars(
        gameEl: HTMLElement,
        flashVars: string,
        websocketBridge: string | boolean,
        dataOverride?: Partial<KubeDataFlashVars>,
        noaOptionsOverride?: any
    ) {
        const dict = parseFlashVars<MapFlashVars>(flashVars, KUBE_KEY_PREFIX)
        console.log(dict)
        const server = dict.infos._s.replace(".com", ".com:6767")
        const tora = new ToraKubeClient(
            dict.k,
            dict.sid,
            server,
            !!websocketBridge,
            typeof websocketBridge === "string" ? websocketBridge : undefined
        )
        return new MapGame(
            tora,
            { domElement: gameEl, ...noaOptionsOverride },
            { ...dict.infos, _x: dict.infos._x * 16, _y: dict.infos._y * 16, ...dataOverride }
        )
    }

    static fromExistingClient(
        gameEl: HTMLElement,
        client: IKubeClient,
        dataOverride?: Partial<KubeDataFlashVars>,
        noaOptionsOverride?: any
    ) {
        return new MapGame(client, { domElement: gameEl, ...noaOptionsOverride }, dataOverride)
    }

    constructor(client: IKubeClient, noaOptionsOverride?: any, dataFlashVars?: Partial<KubeDataFlashVars>) {
        super(
            client,
            {
                ...noaOptionsOverride,
                worldGenWhilePaused: true,
            },
            dataFlashVars
        )
        this.noa.setPaused(true)

        this.config.useLoadInsteandOfWatch = true

        // Fix heading
        this.noa.camera.heading = Math.PI

        // Disabled meshing
        this.noa.world.Chunk.prototype.updateMeshes = () => {}

        // Adapt minimap
        this.minimap.clip = false
        this.minimap.zoneDelimiterWidth = 1
        this.minimap.hidePlayer = true

        // Uncap processing of chunks
        this.noa.world.maxChunksPendingMeshing = 50
        this.noa.world.maxChunksPendingCreation = 50

        this.noa.world.maxProcessingPerRender = 1000 / 30
        this.noa.world.maxProcessingPerTick = 1000 / 30

        this.update = this.update.bind(this)
        setInterval(this.update.bind(this), 500)

        this.on("moveMap", this.updateMap.bind(this))
        this.on("moveMouse", this.updateInfo.bind(this))
        this.on("setMapZoom", this.setMapZoom.bind(this))
    }

    update() {
        this.minimap.update(0)
    }

    updateInfo(x: number, y: number) {
        const pos = this.noa.ents.getPosition(this.noa.playerEntity)
        const zX = Math.floor((pos[0] + x / this.minimap.scale) / CHUNK_SIZE)
        const zY = Math.floor((pos[2] + y / this.minimap.scale) / CHUNK_SIZE)

        const chunk = this.noa.world._storage.getChunkByIndexes(zX, 0, zY)

        const userData: ChunkUserData | undefined = chunk?.userData

        this.emit(
            "uiInfo",
            `${zX}, ${zY}` +
                ` / ${chunk?.id}` +
                ` / ${userData ? userData?.zone?._u : "null"}` +
                ` / ${userData ? userData?.requestSize / 1000 : "0"}k / 128k`
        )
    }

    updateMap(dx: number, dy: number) {
        const pos = this.noa.ents.getPosition(this.noa.playerEntity)
        dx = dx / this.minimap.scale
        dy = dy / this.minimap.scale
        this.noa.ents.setPosition(this.noa.playerEntity, pos[0] + -dx, 1, pos[2] + -dy)

        this.minimap.update(0)
    }

    setMapZoom(zoomLevel: number, size: number) {
        zoomLevel = Math.min(3, Math.max(1, Math.floor(zoomLevel)))
        console.log("setMapZoom", zoomLevel, size)

        const squareSize = Math.ceil(size / (CHUNK_SIZE * zoomLevel)) + 1
        this.minimap.squareSize = squareSize
        this.minimap.scale = zoomLevel

        this.noa.world.setAddRemoveDistance(
            [Math.ceil((squareSize / 2) * 1.3), 0],
            [Math.ceil((squareSize / 2) * 1.5), 1]
        )

        this.minimap.update(0)
    }
}
