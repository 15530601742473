import { parseFlashVars, restoreFlashVars } from "../network/utils"
import { Serializer, Unserializer } from "haxeformat"

export class ConsoleHelper {
    decodeFlashVars(text: string, keyPrefix: string = "") {
        return parseFlashVars<any>(text, keyPrefix)
    }
    encodeFlashVars(obj: any, keyPrefix: string = "") {
        return restoreFlashVars(obj, keyPrefix)
    }

    haxeDeserialize(text: string): any {
        const U = new Unserializer(text)
        return U.unserialize()
    }

    haxeSerialize(obj: any): string {
        const S = new Serializer()
        S.serialize(obj)
        return S.toString()
    }
}
