import box2Bg from "../img/box2bg.gif"
import box2Bottom from "../img/box2bottom.gif"
import box2Top from "../img/box2top.gif"
import { EmotionProps } from "../types"
import { GenericBox } from "./GenericBox"
import { FC, PropsWithChildren } from "react"

export const KubeBox2: FC<PropsWithChildren & EmotionProps> = ({ children, className }) => {
    return (
        <GenericBox
            className={className}
            top={box2Top}
            topHeight={8}
            bottom={box2Bottom}
            bottomHeight={6}
            bg={box2Bg}
            maxWidth={419}>
            {children}
        </GenericBox>
    )
}
