import separatorImg from "../img/tseparator.gif"
import { EmotionProps } from "../types"
import { css } from "@emotion/react"
import { Children, FC, PropsWithChildren, Fragment } from "react"

interface GenericBoxProps {
    top: string
    topHeight: number
    bottom: string
    bottomHeight: number
    bg: string
    maxWidth: number
    separator?: boolean
}

export const GenericBox: FC<PropsWithChildren<GenericBoxProps & EmotionProps>> = ({
    children,
    bg,
    top,
    topHeight,
    bottom,
    bottomHeight,
    maxWidth,
    separator,
    className,
}) => {
    const classes = {
        main: css`
            background: url(${bg}) repeat-y;
            background-size: 100% auto;
            max-width: ${maxWidth}px;
        `,
        topImg: css`
            background: url(${top}) no-repeat;
            background-size: 100% 100%;
            height: ${topHeight}px;
        `,
        bottomImg: css`
            background: url(${bottom}) no-repeat;
            background-size: 100% 100%;
            height: ${bottomHeight}px;
        `,
        separator: css`
            background: url(${separatorImg}) repeat-x;
            background-size: auto 100%;
            height: 5px;
        `,
        content: css`
            padding: 4px 14px;
        `,
    }

    return (
        <div css={classes.main} className={className}>
            <div css={classes.topImg} />
            <>
                {Children.toArray(children).map((child, i) => (
                    <Fragment key={i}>
                        {i !== 0 && separator && <div css={classes.separator} />}
                        <div css={classes.content}>{child}</div>
                    </Fragment>
                ))}
            </>
            <div css={classes.bottomImg} />
        </div>
    )
}
