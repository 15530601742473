import { Game } from "../game/Game"

// Some configs to hack the game from the console
// TODO: Save between reloads
export class Config {
    game: Game

    constructor(game: Game) {
        this.game = game
    }

    pickEveryBlock: boolean = false // Hack to pick all kubes like chests and dolmens kubes (and forums)
    skipPositionUpdates: boolean = false // Skip CSavePos updates
    useTeleportInsteadOfSavePos = false // Use CTeleport commands instead of CSavePos (this allow to run faster without errors)
    useLoadInsteandOfWatch = false // Use CLoad instead of CWatch
    sendSavePhotoCommand = false
    takePutBeforeAnswer = true

    set fov(value: number) {
        // @ts-expect-error
        this.game.noa.rendering._camera.fov = value
    }
    get fov(): number {
        // @ts-expect-error
        return this.game.noa.rendering._camera.fov
    }

    set sprintMult(value: number) {
        this.game.noa.ents.getMovement(this.game.noa.playerEntity).sprintMoveMult = value
    }
    get sprintMult(): number {
        return this.game.noa.ents.getMovement(this.game.noa.playerEntity).sprintMoveMult
    }
}
