import kubeMiddle from "../img/game/kube_middle.jpg"
import { LeftContainer } from "./LeftContainer"
import { RightContainer } from "./RightContainer"
import { css } from "@emotion/react"
import { Game } from "kube-engine/src/game"
import { IKubeClient, LocalGeneratorKubeClient } from "kube-engine/src/network"
import { KubeDataFlashVars } from "kube-engine/src/types"
import { useEffect, useRef, useState } from "react"

const classes = {
    game: css`
        flex-grow: 1;
        background: url(${kubeMiddle});
        background-size: 100% 100%;

        & canvas {
            margin: 10px;

            height: calc(100% - 20px) !important;
            width: calc(100% - 20px) !important;
        }
    `,
    container: css`
        height: 100vh;
        display: flex;

        & > div {
            position: relative;
        }
    `,
    gameMessage: css`
        position: absolute;
        right: 20px;
        bottom: 20px;

        color: #013399;
        font-weight: bold;
        text-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    `,
}

export const GameContainer = ({
    flashVars,
    client,
    dataOverride,
    noaOptionsOverride,
    websocketBridge,
}: {
    flashVars?: string
    client?: IKubeClient
    dataOverride?: Partial<KubeDataFlashVars>
    noaOptionsOverride?: any
    websocketBridge?: string
}) => {
    const gameRef = useRef()
    const [game, setGame] = useState<Game>()

    useEffect(() => {
        if (!gameRef.current || game) return
        let initGame
        if (flashVars !== undefined) {
            initGame = Game.fromFlashVars(gameRef.current, flashVars, websocketBridge, dataOverride, noaOptionsOverride)
        } else if (client !== undefined) {
            initGame = Game.fromExistingClient(gameRef.current, client, dataOverride, noaOptionsOverride)
        } else {
            initGame = Game.fromExistingClient(
                gameRef.current,
                new LocalGeneratorKubeClient(),
                dataOverride,
                noaOptionsOverride
            )
        }

        setGame(initGame)

        // @ts-ignore
        window.game = initGame

        return () => {
            console.log("Unload GameContainer...")
            //location.reload()
        }
    }, [flashVars, client, dataOverride, noaOptionsOverride, websocketBridge])

    return (
        <div css={classes.container}>
            {game && <LeftContainer game={game} />}
            <div id="game" ref={gameRef} css={classes.game}>
                {game && <GameMessage game={game} />}
            </div>
            {game && <RightContainer game={game} />}
        </div>
    )
}

const GameMessage = ({ game }: { game: Game }) => {
    const [isLoading, setIsLoading] = useState(game.isLoading)
    useEffect(() => {
        game.on("uiLoading", setIsLoading)
        return () => {
            game.off("uiLoading", setIsLoading)
        }
    }, [game])

    if (!isLoading) return null
    return <div css={classes.gameMessage}>Chargement du monde...</div>
}
